import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	createMeeting: ['meeting']
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	meetings: []
})

/* ------------- Reducers ------------- */

// Create meeting
export const createMeeting = (state = INITIAL_STATE, {meeting}) => {
	return {...state, meetings: [...state.meetings, meeting]}
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.CREATE_MEETING]: createMeeting,
})
