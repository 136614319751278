import React, {createRef, useEffect, useRef, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {DetailsCard, DetailsTable, DetailsContainer, DataTable, Forms} from "src/admin-utils"
import __, {lang} from "src/admin-utils/LanguageHelper"
import Page from "../../components/Page"
import {successAlert} from "../../utils/dialogs"
import {useSelector} from "react-redux"
import {useHistory} from "react-router"

const useStyles = makeStyles((theme) => ({}))

function ChangePassword({onDone}) {
  const history = useHistory()
  const classes = useStyles()
  const tableRef = useRef()
  const {user: {id}} = useSelector((state) => state.session)
  let formRef

  return (
    <Forms
      width={500}
      type={'inline'}
      onRef={ref => formRef = ref}
      title={__('تغيير كلمة المرور ', 'Change Password')}
      editTitle={__('تغيير كلمة المرور', 'Edit Password')}
      endpoint={`users/${id}`}
      fields={[
        {
          title: __('كلمة المرور القديمة', 'Old Password'),
          field: 'old_password',
          type: 'password',
          rules: ['required'],
        },
        {
          title: __('كلمة المرورالجديدة', 'New Password'),
          field: 'password',
          type: 'password',
          rules: ['required'],
        },
      ]}
      onFinish={() => {
        onDone()
        successAlert({
          title: lang('Password changed successfully', 'تم تغيير كلمة المرور بنجاح')
        })

        setTimeout(() => {
          history.push('/auth/login')
        }, 1000)
      }}
    />
  )
}

export default ChangePassword
