/* eslint-disable no-undef */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Button, Container } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import LinearProgress from "@material-ui/core/LinearProgress";
import DetailsPanel from "../admin-utils/Components/Page/DetailsPanel";
import MessageLine from "./MessageLine"

const isDev = process.env.NODE_ENV !== "production";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    position: "relative",
    marginTop: 120,
    [theme.breakpoints.down("md")]: {
      marginTop: 0
    }
  },
  devBar: {
    backgroundColor: theme.palette.devBar,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.devBar}`,
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  forceReload: {
    float: "right"
  },
  loading: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0
  }
}));

function Page({ title, subTitle, children, noBread, left, loading, ...rest }) {
  const classes = useStyles();
  const [updater, setUpdater] = useState(true);

  return (
    <div {...rest} className={classes.root}>
      {loading && <LinearProgress className={classes.loading} />}

      {isDev && (
        <div className={classes.devBar}>
          <Button
            className={classes.forceReload}
            variant="outlined"
            color="primary"
            size={"small"}
            onClick={() => {
              setUpdater(false);
              setTimeout(() => {
                setUpdater(true);
              });
            }}
          >
            {"Force Reload"}
          </Button>
        </div>
      )}

      {title.props ? (
        <FormattedMessage id={title.props.id}>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
      ) : (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}

      <Container maxWidth={false}>
        <MessageLine />
        <br />

        {updater && children}
      </Container>
    </div>
  );
}

// Pusher
Page.pushView = function(params) {
  if (params.route) {
    window.history.pushState({}, "", params.route);
  }

  DetailsPanel.show(params);
};

Page.hideView = function(params) {
  if (params) {
    window.history.pushState({}, "", params.route);
  }

  DetailsPanel.hide();
};

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.any
};

export default Page;
