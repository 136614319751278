import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Divider } from "@material-ui/core";
import Reactions from "./Reactions";
import CommentBubble from "./CommentBubble";
import CommentForm from "./CommentForm";
import { BiCircle } from "react-icons/all";
import PostBody from "./PostBody";
import { useDispatch, useSelector } from "react-redux";
import { getData, postRequest } from "../../admin-utils/API";
import FilesGallery from "./FilesGallery";
import _ from "lodash";
import PostHeader from "./PostHeader";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    display: "flex",
    alignItems: "center"
  },
  accessTimeIcon: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    height: 14,
    width: 14,
    marginRight: 6
  },
  content: {
    paddingTop: 0
  },
  mediaArea: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 400,
    backgroundPosition: "initial"
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  dot: {
    color: theme.palette.primary.main,
    top: 3,
    position: "relative",
    marginLeft: theme.spacing(1)
  },
  moreButton: {
    color: theme.palette.secondary.main
  },
  postAction: {
    marginTop: 8
  },
  unFollowButton: {
    backgroundColor: "#FDE6D1",
    color: "#E23D1F",
    "&:hover": {
      backgroundColor: "#FCC6A5"
    }
  },
  shareKnowledge: {
    border: "1px solid",
    borderColor: theme.palette.primary.main
  },
  shareComment: {
    padding: theme.spacing(2, 2, 0, 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  shareContainer: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      width: 2,
      height: "calc(100% - 3px)",
      backgroundColor: theme.palette.secondary.main,
      top: -41,
      left: 7,
      zIndex: -1
    }
  },
  shareCircle: {
    position: "absolute",
    left: 0,
    bottom: 30,
    color: theme.palette.secondary.main
  },
  comments: {
    paddingTop: theme.spacing(1),
    maxHeight: 400,
    direction: "ltr"
  }
}));

function PostCard({ post: originalPost, className, isAlert, ...rest }) {
  const classes = useStyles();
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);
  const { members: followings, posts: followedPosts } = useSelector(
    state => state.followings
  );

  const dispatch = useDispatch();
  const { pusherData } = useSelector(state => state.pusher);

  const [post, setPost] = useState(originalPost);
  const [comments, setComments] = useState(post.comments);

  const followingMember = followings.find(i => i === post.created_by);
  const isFollowing = followedPosts.find(i => i === post.id);

  const [loading, setLoading] = useState(false);
  const [updater, setUpdater] = useState(false);
  const [commentsCount, setCommentsCount] = useState(comments.length);

  const postType = post.post_type;
  const noReport = [
    "announcement",
    "new_hire",
    "campaign",
    "alert",
    "news"
  ].includes(postType);
  const isShareKnowledge = postType === "share_knowledge";

  useEffect(() => {
    if (pusherData) {
      if (
        pusherData.id === originalPost.id &&
        member_id !== pusherData.member
      ) {
        getPostDetails().finally();
      }
    }
  }, [pusherData]);

  if (!post.creator) return null;

  const deletePost = async () => {
    await postRequest(`post/${post.id}`, {}, token, "delete");
    post.creator = null;

    setUpdater(!updater);
  };

  const getPostDetails = async () => {
    setLoading(true);
    let fetchedPost = await getData(
      `post/${originalPost.id}`,
      {
        include: "creator,comments,comments.member,reactions,alert_type"
      },
      token
    );

    const post = fetchedPost.data;

    setPost(post);
    setComments(post.comments);
    setCommentsCount(post.comments.length);

    setLoading(false);
  };

  const onCommentAdded = comment => {
    setCommentsCount(commentsCount + 1);
    setComments([comment, ...comments]);
  };

  return (
    <>
      <Card
        {...rest}
        className={clsx(
          classes.root,
          className,
          isShareKnowledge && classes.shareKnowledge
        )}
      >
        <PostHeader
          post={post}
          onReply={reply => {
            setComments([reply, ...comments]);
          }}
          onDelete={() => {
            deletePost().finally();
          }}
          onEdit={edited => {
            post.title = edited.title;
            post.description = edited.description;

            setUpdater(!updater);
          }}
        />

        <CardContent className={classes.content}>
          <PostBody post={post} isAlertPopUp={isAlert} />

          {post.gallery.media.length > 0 && (
            <FilesGallery
              images={_.isString(post.gallery) ? [post.gallery] : post.gallery}
            />
          )}

          <Reactions post={post} comments={commentsCount} loading={loading} />

          {!isShareKnowledge && (
            <>
              {comments.length > 0 && <Divider className={classes.divider} />}

              {comments.length > 0 && (
                <PerfectScrollbar style={{ direction: "ltr" }}>
                  <div className={classes.comments}>
                    {comments.map(comment => (
                      <CommentBubble
                        post={post}
                        comment={comment}
                        key={comment.id}
                        onReply={onCommentAdded}
                      />
                    ))}
                  </div>
                </PerfectScrollbar>
              )}
              <Divider className={classes.divider} />
              <CommentForm post={post} onCommentAdded={onCommentAdded} />
            </>
          )}
        </CardContent>
      </Card>

      {isShareKnowledge && comments.length > 0 && (
        <div>
          {comments.map(comment => (
            <div className={classes.shareContainer}>
              <BiCircle className={classes.shareCircle} />
              <Card className={classes.shareComment}>
                <CommentBubble
                  post={post}
                  isShareKnowledge
                  comment={comment}
                  key={comment.id}
                  onReply={onCommentAdded}
                />
              </Card>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

PostCard.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired
};

export default PostCard;
