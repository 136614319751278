import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import { Player } from "video-react";

const useStyles = makeStyles(theme => ({
  videoContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .video-react": {
      maxWidth: 800
    }
  }
}));

function VideoPreview({ link }) {
  const classes = useStyles();

  return (
    <Box className={classes.videoContainer}>
      <Player>
        <source src={link} />
      </Player>
    </Box>
  );
}

export default VideoPreview;
