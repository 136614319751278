import logo from './logo'
import __ from "../admin-utils/LanguageHelper"

const baseDomain = 'https://api.awasul1.com'
const baseUrl = baseDomain + '/v1'
const authables = []
const currency = __('ريال', 'SAR')

const host = window.location.host
const parts = host.split(".")

export const subdomain = parts[0]

// Form Options
export const formOptions = {
  keepData: false,
  clearInputs: false,
  maxUpload: 5000
}

// Table Options
export const tableOptions = {
  allowExport: true
}

// PDF Options
export const pdfOptions = {
  allowPrint: false,
  logo
}

// Global Options
export const globalOptions = {
  enableAccessGuard: false
}

// Agora Config
export const agoraConfig = {
  // Pass your app ID here
  appId: "f735a44e5d664af083d44778ff786f91",
}

export const rtc = {
  // For the local client
  client: null,
  // For the local audio and video tracks
  localAudioTrack: null,
  localVideoTrack: null,
}

export const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {})

export
{
  baseUrl,
  baseDomain,
  authables,
  currency,

}
