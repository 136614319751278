export function getAggregateType(mime) {
  switch (mime) {
    // Document
    case 'application/msword':
    case 'text/rtf':
      return 'document'
    // Archive
    case '':
    case 'application/zip':
      return 'archive'
    // PDF
    case 'application/pdf':
      return 'pdf'
    // Audio
    case 'audio/mpeg':
      return 'audio'
    // Video
    case 'video/mp4':
      return 'video'
    // Presentation
    case 'application/vnd.ms-powerpoint':
      return 'presentation'
    // Spreadsheet
    case 'text/csv':
    case 'text/xml':
    case 'application/vnd.oasis.opendocument.spreadsheet':
      return 'spreadsheet'
  }
}

export default (media, isMime = false) => {
  let icon = '/images/file_types/'

  let aggregate_type = media.aggregate_type
  if(isMime) {
    aggregate_type = getAggregateType(media)
  }

  // console.log(media)

  switch (aggregate_type) {
    case 'image':
    case 'video':
    case 'vector':
      icon += 'image'
      break
    case 'archive':
      icon += 'zip'
      break
    case 'document':
      icon += 'doc'
      break
    case 'audio':
      icon += 'audio'
      break
    case 'presentation':
      icon += 'ppt'
      break
    case 'pdf':
      icon += 'pdf'
      break
    case 'spreadsheet':
      icon += 'xls'
      break
  }

  return `${icon}.svg`
}
