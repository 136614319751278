import React from "react";
import { makeStyles } from "@material-ui/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Menu } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  menuIcon: {
    fontSize: 20,
    position: "relative"
  },
  smallMenuIcon: {
    fontSize: 16,
    position: "relative"
  },
  menuText: {
    color: theme.palette.text.primary
  },
  smallMenuText: {
    "& > *": {
      fontSize: "13px!important"
    }
  },
  menuItem: {
    borderBottom: "1px solid #D2D8E6",
    paddingBottom: 5,
    paddingTop: 5,
    "&:last-child": {
      borderBottom: 0
    }
  },
  smallMenuItem: {
    paddingBottom: 0,
    paddingTop: 0
  },
  menu: {
    border: "1px solid #D2D8E6",
    borderRadius: 8
  },
  paper: {
    borderRadius: 8,
    border: "1px solid #D2D8E6"
  },
  paperWithArrow: {
    "&::before": {
      content: "''",
      height: 13,
      width: 13,
      position: "absolute",
      backgroundColor: "#ffffff",
      top: -7,
      right: 18,
      borderTop: "#D2D8E6 solid 1px",
      borderLeft: "#D2D8E6 solid 1px",
      transform: "rotate(45deg)"
    }
  }
}));

function DropdownMenu({
  anchorEl,
  handleClose,
  items = [],
  withArrow = true,
  small = false,
  isContext = false,
  state
}) {
  const classes = useStyles();

  if (isContext) {
    // if(!state.mouseY) return null
    return (
      <Menu
        // keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
        className={classes.paper}
        PaperProps={{
          style: {
            borderRadius: 8,
            border: "1px solid #D2D8E6"
          }
        }}
        MenuListProps={{
          style: {
            padding: 0
          }
        }}
      >
        {items.map(({ title, onClick, link, icon: Icon, hide }) => {
          if (hide) return null;
          return (
            <MenuItem
              className={clsx(classes.menuItem, small && classes.smallMenuItem)}
              onClick={() => {
                onClick && onClick(title);
                handleClose();
              }}
              component={link && Link}
              to={link}
            >
              {Icon && (
                <ListItemIcon>
                  <Icon
                    className={clsx(
                      classes.menuIcon,
                      small && classes.smallMenuIcon
                    )}
                  />
                </ListItemIcon>
              )}
              <ListItemText
                className={clsx(
                  classes.menuText,
                  small && classes.smallMenuText
                )}
                primary={title}
              />
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 1, marginTop: 10, padding: 0 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom"
          }}
        >
          <Paper
            className={clsx(classes.paper, withArrow && classes.paperWithArrow)}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={Boolean(anchorEl)}
                id="menu-list-grow"
                style={{ padding: 0 }}
              >
                {items.map(({ title, onClick, link, icon: Icon, hide }) => {
                  if (hide) return null;
                  return (
                    <MenuItem
                      className={clsx(
                        classes.menuItem,
                        small && classes.smallMenuItem
                      )}
                      onClick={() => {
                        onClick && onClick(title);
                        handleClose();
                      }}
                      component={link && Link}
                      to={link}
                    >
                      {Icon && (
                        <ListItemIcon>
                          <Icon
                            className={clsx(
                              classes.menuIcon,
                              small && classes.smallMenuIcon
                            )}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        className={clsx(
                          classes.menuText,
                          small && classes.smallMenuText
                        )}
                        primary={title}
                      />
                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}

export default DropdownMenu;
