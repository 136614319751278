import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import { lang } from "../../admin-utils/LanguageHelper";
import { getData } from "../../admin-utils/API";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
  popover: {
    width: 350
  },
  icon: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.contrastText
  },
  friendsHeader: {
    backgroundColor: "#f5f5f5"
  },
  listItem: {
    alignItems: "flex-start"
  }
}));

function FriendsDrawer({ isOpen, onClose }) {
  const classes = useStyles();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    token,
    user: { member_id }
  } = useSelector(state => state.session);

  useEffect(() => {
    if (isOpen) {
      getFriends().finally();
    }
  }, [isOpen]);

  async function getFriends() {
    setLoading(true);
    let fetchedData = await getData(
      "member",
      {
        orderBy: "created_at",
        sortedBy: "desc",
        limit: 1000,
        excludeMe: true
      },
      token
    );

    setFriends(fetchedData.data);
    setLoading(false);
  }

  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.popover }}
        open={isOpen}
        variant="temporary"
        onClose={onClose}
      >
        <Box p={2} className={classes.friendsHeader}>
          <Typography variant="h4" color="textPrimary">
            {lang("Friends", "الاصدقاء")}
          </Typography>
        </Box>

        <Divider />

        {loading ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {lang("Loading...", "جاري التحميل...")}
            </Typography>
          </Box>
        ) : friends.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {lang("You have no friends", "لا يوجد اصدقاء لديك")}
            </Typography>
          </Box>
        ) : (
          <List disablePadding>
            {friends.map(friends => {
              return (
                <ListItem
                  className={classes.listItem}
                  component={RouterLink}
                  divider
                  key={friends.id}
                  state={{ fromDashboard: true }}
                  to={`/profile/${friends.username}`}
                  onClick={onClose}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.icon} src={friends.logo} />
                  </ListItemAvatar>
                  <ListItemText
                    secondary={
                      <Box my={-0.6}>
                        <Typography
                          variant="subtitle2"
                          component="p"
                          color="textPrimary"
                        >
                          {friends.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          color="secondary"
                        >
                          {friends.email}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </Drawer>
    </>
  );
}

export default FriendsDrawer;
