/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from "react"
import DashboardIcon from "@material-ui/icons/DashboardOutlined"
import LocalOfferIcon from "@material-ui/icons/LocalOffer"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import UpdateIcon from "@material-ui/icons/Update"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
import GridOnIcon from "@material-ui/icons/GridOn"
import EventIcon from "@material-ui/icons/Event"
import InputIcon from "@material-ui/icons/Input"


import {FormattedMessage} from "react-intl"
import __, {lang} from "src/admin-utils/LanguageHelper"
import {
  AiFillSound,
  AiOutlineFundProjectionScreen,
  AiOutlineHome,
  AiOutlineNotification,
  AiOutlineSetting,
  AiOutlineUserAdd,
  BiCalendar,
  BiFile,
  BsBuilding,
  BsChatDots,
  BsFillExclamationSquareFill,
  BsGrid3X3,
  BsInfoCircle,
  BsListCheck,
  BsNewspaper,
  FiAlertTriangle,
  FiLogOut,
  FiRefreshCcw,
  GiWireframeGlobe,
  GrDocumentUser,
  HiOutlineUserGroup,
  HiUserGroup,
  ImTree,
  MdAssignmentInd,
  Ri24HoursLine,
  RiDashboardLine,
  RiFileChartLine,
  RiFileCopy2Line,
  RiFileListLine,
  RiLogoutCircleLine,
  RiShieldUserLine,
  RiUser2Fill,
  SiAdguard,
  VscCalendar
} from "react-icons/all"
import {Page} from "../../admin-utils"
import MeetingRoomOutlinedIcon from "@material-ui/icons/MeetingRoomOutlined"
import superAdminConfig from "./superAdminConfig"

export default (isAdmin = false) => {
  return [
    {
      items: []
    },
    {
      items: [
        {
          title: __("الصفحة الرئيسية للمنشأة", "Company Home"),
          href: "/my/company",
          icon: BsBuilding
        },
        {
          title: __("المجتمع الوظيفي", "Community"),
          href: "/community",
          icon: AiOutlineHome
        },
        {
          title: __("الانشطة", "Activities"),
          href: "/activities",
          icon: RiFileListLine
        },
        {
          title: __("الهيكل التنظيمي", "Organizational structure"),
          href: "/structure/employees",
          icon: ImTree
        }
      ]
    },
    {
      items: [
        {
          title: __("المحادثات", "Chat"),
          href: "/chat",
          icon: BsChatDots
        },
        {
          title: __("الإقرار الالكتروني", "Electronic Acknowledgment"),
          href: "/employee/contracts",
          icon: BiFile
        },
        {
          title: __("دليل الهاتف للموظفين", "Employees Directory"),
          href: "/company/directory",
          icon: RiUser2Fill
        },
        {
          title: __("اجتماع", "Meeting"),
          href: "/meeting",
          icon: MeetingRoomOutlinedIcon
        },
        {
          title: __("المجموعات", "Groups"),
          href: "/groups",
          icon: HiOutlineUserGroup
        },
        // {
        //   title: __("المهام", "Tasks"),
        //   href: "/tasks",
        //   icon: BsListCheck
        // },
        {
          title: __("التقويم", "Calendar"),
          href: "/calendar",
          icon: BiCalendar
        },
        {
          title: __("الملفات", "Files"),
          href: "/drive",
          icon: RiFileCopy2Line
        }
      ]
    },
    {
      items: [
        {
          title: __("تقويم المنشأة", "Company Calendar"),
          href: "/company/calendar",
          icon: VscCalendar
        },
        // {
        //   title: __("المشاريع", "Projects"),
        //   href: "/projects",
        //   icon: AiOutlineFundProjectionScreen
        // },
        {
          title: __("الحملات", "Campaigns"),
          href: "/feed/campaign",
          icon: BsGrid3X3
        },
        {
          title: __("التعميمات", "Announcements"),
          href: "/feed/announcement",
          icon: AiOutlineNotification
        },
        {
          title: __("التحذيرات", "Alerts"),
          href: "/feed/alert",
          icon: FiAlertTriangle
        },
        // {
        //   title: __('التحذيرات 2', 'Alerts'),
        //   href: '/alerts',
        //   icon: FiAlertTriangle
        // },
        {
          title: __("تبادل الخبرة", "Share Knowledge"),
          href: "/feed/share_knowledge",
          icon: Ri24HoursLine
        },
        {
          title: __("الاخبار", "News"),
          href: "/feed/news",
          icon: BsNewspaper
        }
      ]
    },
    ...(isAdmin ? superAdminConfig : []),
    // {
    //   items: [
    //     {
    //       title: __('تحديث الباقة الخاصة بك', 'Up Grade Your Plan'),
    //       href: '/auth/login',
    //       icon: FiRefreshCcw,
    //     },
    //   ]
    // },
    // {
    //   subheader: __('ادارة المستخدمين', 'User Management'),
    //   items: [
    //     {
    //       title: __('ادارة المستخدمين', 'User Management'),
    //       href: '/user/management',
    //       icon: MdAssignmentInd,
    //       items: [
    //         {
    //           title: __('الادوار', 'Roles'),
    //           href: '/user/management/roles',
    //         },
    //         {
    //           title: __('العملاء', 'Users'),
    //           href: '/user/management/users',
    //         },
    //       ]
    //     },
    //   ]
    // },

    {
      items: [
        {
          title: __("تسجيل الخروج", "Log Out"),
          href: "/auth/login",
          icon: FiLogOut,
          hideInHome: true
        }
      ]
    }
  ]
}
