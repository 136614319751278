import React from "react";
import { Router } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { createBrowserHistory } from "history";
import MomentUtils from "@date-io/moment";
import { Provider as StoreProvider, useSelector } from "react-redux";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-perfect-scrollbar/dist/css/styles.css";
import { theme, themeWithRtl } from "./theme";
import configureStore from "./redux/configureStore";
import routes from "./routes";
import ScrollReset from "./components/ScrollReset";
import StylesProvider from "./components/StylesProvider";
import { IntlProvider } from "react-intl";
import * as messages_ar from "./locales/ar";
import * as messages_en from "./locales/en";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import "./mixins/chartjs";
import "./mixins/moment";
import "./mixins/validate";
import "./mixins/prismjs";
import "./mock";
import "./assets/scss/main.scss";
import AuthGuard from "./components/AuthGuard/AuthGuard";
import DetailsPanel from "./admin-utils/Components/Page/DetailsPanel";
import CallModal from "./views/Chat/Call/CallModal";
import { getQueryParams } from "./utils/globals";
import MessageLine from "./components/MessageLine";

const history = createBrowserHistory();
const store = configureStore();

const useStyles = makeStyles(theme => ({
  notificationRoot: {
    zIndex: "9999999 !important"
  }
}));

function App() {
  const classes = useStyles();
  let savedDirection = localStorage.getItem("direction");
  const queryParams = getQueryParams();

  localStorage.removeItem('pusherConnected')

  if (queryParams.lang) {
    savedDirection = queryParams.lang === "ar" ? "rtl" : "ltr";
    localStorage.setItem("direction", savedDirection);
  }

  const messages = {
    en: messages_en,
    ar: messages_ar
  };

  const language = savedDirection === "rtl" ? "ar" : "en";

  return (
    <StoreProvider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <IntlProvider locale={language} messages={messages[language].default}>
          <ThemeProvider
            theme={savedDirection === "rtl" ? themeWithRtl : theme}
          >
            <StylesProvider direction={savedDirection}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router history={history}>
                  <SnackbarProvider
                    maxSnack={1}
                    classes={{ containerRoot: classes.notificationRoot }}
                  >
                    <ScrollReset />
                    <AuthGuard permission={"access-dashboard"}>
                      {renderRoutes(routes)}

                      <DetailsPanel
                        ref={c => {
                          if (c) DetailsPanel.detailsPanelInstance = c;
                        }}
                      />

                      <CallModal
                        ref={c => {
                          if (c) CallModal.callModalInstance = c;
                        }}
                      />
                    </AuthGuard>
                  </SnackbarProvider>
                </Router>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </IntlProvider>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
