import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import {
  FaEye,
  FaRegThumbsUp,
  FaThumbsUp,
  FaRegComment
} from "react-icons/all";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { lang } from "../../admin-utils/LanguageHelper";
import { useSelector } from "react-redux";
import { postRequest, remove_record } from "../../admin-utils/API";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative"
  },
  likeButton: {
    color: theme.palette.secondary.main
  },
  likedButton: {
    color: theme.palette.primary.main
  },
  viewButton: {
    marginLeft: "auto",
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    cursor: 'default'
  },
  shareIcon: {
    marginRight: theme.spacing(1)
  },
  reactionIcon: {
    width: 32,
    height: 32,
    "&:hover": {
      transform: "scale(1.3)"
    }
  },
  myReactionIcon: {
    width: 28,
    height: 28
  },
  reactionsContainer: {
    borderRadius: 20,
    position: "absolute",
    top: -35,
    zIndex: 99
  },
  reactionButton: {
    marginRight: theme.spacing(0.3),
    marginLeft: theme.spacing(0.3)
  },
  tooltip: {
    backgroundColor: theme.palette.common.black
  },
  postInfoContainer: {
    display: "flex",
    alignItems: "center"
  }
}));

function Reactions({ post, className, comments, loading, ...rest }) {
  const classes = useStyles();
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);

  const [showReactions, setShowReactions] = useState(false);
  const noReactions = ["alert", "share_knowledge"].includes(post.post_type);

  const [reactions] = useState(post.reactions);
  const [myReaction, setMyReaction] = useState(
    reactions.find(i => i.created_by === member_id)
  );

  function getReactions(reaction) {
    let reactions = [
      {
        key: "like",
        name: lang("Like", "أعجبني"),
        icon: "/images/reactions/like.png"
      },
      {
        key: "celebrate",
        name: lang("Celebrate", "احتفل"),
        icon: "/images/reactions/celebrate.png"
      },
      {
        key: "love",
        name: lang("Love", "أحببته"),
        icon: "/images/reactions/love.png"
      },
      {
        key: "insightful",
        name: lang("Insightful", "عميق"),
        icon: "/images/reactions/insightful.png"
      },
      {
        key: "curious",
        name: lang("Curious", "فضولي"),
        icon: "/images/reactions/curious.png"
      }
    ];

    if (reaction) {
      return reactions.find(i => i.key === reaction);
    }

    return reactions;
  }

  const removeReaction = () => {
    setShowReactions(false);
    setMyReaction(null);
    remove_record("post_reaction", myReaction.id, token).finally();
  };

  const saveReaction = async reaction => {
    setShowReactions(false);

    let endpoint = "post_reaction";
    if (myReaction) {
      endpoint = `post_reaction/${myReaction.id}`;
    }

    let request = await postRequest(
      endpoint,
      {
        created_by: member_id,
        post_id: post.id,
        reaction
      },
      token
    );

    let reactions = request.data.data;
    setMyReaction(reactions.find(i => i.created_by === member_id));

    // global.feedChannel.trigger('client-reactions-update', {
    //   target: member_id,
    //   post_id: post.id,
    //   reactions
    // })
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {!noReactions && (
        <div
          onMouseEnter={() => setShowReactions(true)}
          onMouseLeave={() => setShowReactions(false)}
        >
          {myReaction ? (
            <>
              {myReaction.reaction === "like" ? (
                <IconButton
                  onClick={removeReaction}
                  className={classes.likedButton}
                  size="small"
                >
                  <FaThumbsUp size={20} />
                </IconButton>
              ) : (
                <IconButton
                  onClick={removeReaction}
                  className={classes.likeButton}
                  size="small"
                >
                  <Avatar
                    alt={getReactions(myReaction.reaction).key}
                    src={getReactions(myReaction.reaction).icon}
                    className={classes.myReactionIcon}
                  />
                </IconButton>
              )}
            </>
          ) : (
            <IconButton
              onClick={() => saveReaction("like")}
              className={classes.likeButton}
              size="small"
            >
              <FaRegThumbsUp size={20} />
            </IconButton>
          )}

          {showReactions && (
            <Paper className={classes.reactionsContainer}>
              {getReactions().map(reaction => (
                <Tooltip
                  placement={"top"}
                  title={reaction.name}
                  classes={{ tooltip: classes.tooltip }}
                >
                  <IconButton
                    className={classes.reactionButton}
                    onClick={() => saveReaction(reaction.key)}
                    size="small"
                  >
                    <Avatar
                      alt={reaction.key}
                      src={reaction.icon}
                      className={classes.reactionIcon}
                    />
                  </IconButton>
                </Tooltip>
              ))}
            </Paper>
          )}
        </div>
      )}
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        {loading && <CircularProgress color="primary" size={20} />}

        <Box className={classes.postInfoContainer} mr={2} ml={2}>
          <IconButton className={classes.viewButton} size="small">
            <FaRegComment size={20} />
          </IconButton>

          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontFamily: "Roboto" }}
          >
            {comments}
          </Typography>
        </Box>

        <Box className={classes.postInfoContainer}>
          <IconButton className={classes.viewButton} size="small">
            <FaEye size={20} />
          </IconButton>

          <Typography
            color="textSecondary"
            variant="h6"
            style={{ fontFamily: "Roboto" }}
          >
            {post.views_count}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

Reactions.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object.isRequired
};

export default Reactions;
