import React, {
  useEffect,
  useRef, useState
} from 'react'
import ReactDOM from "react-dom"
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Avatar, Box, makeStyles, Typography} from '@material-ui/core'
import AgoraRTC from "agora-rtc-sdk-ng"
import {rtc, agoraConfig} from 'src/utils/globals'
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import {lang} from "../../../admin-utils/LanguageHelper"
import IconButton from "@material-ui/core/IconButton"
import {CallRounded} from "@material-ui/icons"
import {MdCallEnd, MdMic, MdCall} from "react-icons/all"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import color from 'color'
import {postRequest} from "../../../admin-utils/API"
import useAgora from "./Hooks/useAgora"
import MediaPlayer from "./Components/MediaPlayer"
import VideoStream from "./Components/VideoStream"
import {Case, Switch, Default, If} from "react-if"

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden'
  },
  stream: {
    width: 400,
    height: 320
  },
  groupAvatar: {
    height: 46,
    width: 46,
    '&:first-child': {
      marginTop: 10
    }
  },
  avatar: {
    height: 75,
    width: 75,
    '& img': {
      border: '2px solid rgba(255,255,255,0.5)',
      borderRadius: '50%'
    }
  },
  overlay: {
    backgroundColor: '#1f1f1f',
    position: 'absolute',
    top: theme.spacing(0.3),
    right: theme.spacing(0.3),
    bottom: theme.spacing(0.3),
    left: theme.spacing(0.3),
    borderRadius: theme.spacing(2),
    zIndex: -1
  },
  chatName: {
    color: 'white',
    fontSize: 18,
    marginTop: theme.spacing(1),
    textAlign: 'center'
  },
  calling: {
    textAlign: 'center'
  },
  joinedControls: {
    position: 'absolute',
    bottom: 30,
    left: 10,
    right: 10,
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const StyledIconButton = withStyles((theme) => ({
  root: {
    width: 50,
    height: 50,
    fontSize: 28,
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    backgroundColor: (props) => props.background,
    color: (props) => props.color || 'white',
    '&:hover': {
      backgroundColor: (props) => color(props.background).darken(0.2).hex(),
    }
  },
}))(IconButton)

const Ringing = new Audio('/sound/ringtone.mp3')
const Calling = new Audio('/sound/calling.mp3')

let callChannel
const client = AgoraRTC.createClient({mode: "rtc", codec: "vp8"})

function Call({onClose, members, withVideo = false, isIncomingCall = false}) {
  const classes = useStyles()
  const {id} = useParams()
  const {token} = useSelector((state) => state.session)

  const [joined, setJoined] = useState(false)
  const [isCalling, setIsCalling] = useState(true)
  const [incomingCall, setIncomingCall] = useState(isIncomingCall)
  const [onCall, setOnCall] = useState(false)
  const [callStatus, setCallStatus] = useState(true)
  const [channelName, setChannelName] = useState(null)
  const [agoraToken, setAgoraToken] = useState(null)
  const {
    localAudioTrack, localVideoTrack, leave, join, joinState, remoteUsers
  } = useAgora(client, withVideo)

  const chatName = members.map(i => i.name).join(', ')

  useEffect(() => {
    init().finally()

    if(incomingCall) {
      Ringing.loop = true
      Ringing.play().finally()
    }else{
      Calling.loop = true
      Calling.play().finally()
    }

    return () => {
      Calling.pause()
      Ringing.pause()
    }
  }, [])

  async function init() {
    let params = {
      users: members.map(i => i.user_id),
      chat: id,
      title: chatName,
    }

    if (withVideo) {
      params.withVideo = true
    }

    const request = await postRequest('agora/auth', params, token, 'post')

    const {token: agoraToken, channelName} = request.data
    setAgoraToken(agoraToken)
    setChannelName(channelName)

    callChannel = global.pusher.subscribe(`private-call-channel-${channelName}`)
    callChannel.bind('client-status-update', function ({status}) {
      console.log(status)
      setCallStatus(status)

      if (status === "declined") {
        // declineCall()
      }

      if (status === "onCall") {
        acceptCall(false)
      }

      if (status === "callEnded") {
        endCall()
      }
    })

    if (!incomingCall) {
      await join(agoraConfig.appId, channelName, agoraToken)
    }
  }

  async function acceptCall(startingCall = true) {
    if (!onCall) {
      Calling.pause()
      Ringing.pause()

      setOnCall(true)
      setIsCalling(false)
      setIncomingCall(false)

      // Show players
      setJoined(true)

      console.log('Accepted')

      // if (startingCall) {
      //   await join(agoraConfig.appId, channelName, agoraToken)
      // }

      // this.timer = setInterval(() => {
      //   let num = (Number(this.state.secondsCounter) + 1).toString(),
      //     count = this.state.minutesCounter
      //
      //   if (Number(this.state.secondsCounter) === 59) {
      //     count = (Number(this.state.minutesCounter) + 1).toString()
      //     num = '00'
      //   }
      //
      //   this.setState({
      //     minutesCounter: count.length === 1 ? '0' + count : count,
      //     secondsCounter: num.length === 1 ? '0' + num : num
      //   })
      // }, 1000)

      callChannel.trigger('client-status-update', {
        status: 'onCall'
      })
    }
  }

  async function endCall() {
    setJoined(false)

    // Leave the channel
    await leave()

    onClose && onClose()

    callChannel.trigger('client-status-update', {
      status: 'callEnded'
    })
  }

  const ChatAvatar = () => (
    <>
      {members.length > 1 ? (
        <AvatarGroup max={2}>
          <Avatar
            alt="Person"
            className={classes.groupAvatar}
            src={members[0].image}
          />
          <Avatar
            alt="Person"
            className={classes.groupAvatar}
            src={members[1].image}
          />
        </AvatarGroup>
      ) : (
        <Avatar
          alt="Person"
          className={classes.avatar}
          src={members[0].image}
        />
      )}
    </>
  )

  return (
    <Box className={classes.root}>
      {isCalling && (
        <Box
          style={{height: '100%'}}
          display="flex"
          alignItems="center"
          flexDirection={'column'}
        >
          <Box className={classes.overlay}/>

          {chatName && (
            <Box
              p={2}
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent={'center'}
              flexDirection={'column'}
            >
              <ChatAvatar/>

              <Box my={1} alignItems={'center'}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.chatName}
                >
                  {chatName}
                </Typography>

                <Typography
                  variant="body1"
                  color="textSecondary"
                  className={classes.calling}
                >
                  <Switch>
                    <Default>
                      {lang('Calling...', 'جاري الاتصال...')}
                    </Default>
                    <Case condition={incomingCall}>
                      {lang('Incoming Call...', 'مكالمة واردة...')}
                    </Case>
                  </Switch>
                </Typography>
              </Box>
            </Box>
          )}

          <Box my={1} alignItems={'center'}>
            <StyledIconButton background={'#F42808'} onClick={endCall}>
              <MdCallEnd/>
            </StyledIconButton>

            <StyledIconButton background={'#858687'}>
              <MdMic/>
            </StyledIconButton>

            <If condition={incomingCall}>
              <StyledIconButton background={'#1dbe00'} onClick={acceptCall}>
                <MdCall/>
              </StyledIconButton>
            </If>

          </Box>
        </Box>
      )}

      {joined && (
        <>
          <VideoStream
            withVideo={withVideo}
            videoTrack={localVideoTrack}
            audioTrack={localAudioTrack}
            remoteUsers={remoteUsers}
          />

          <Box className={classes.joinedControls}>
            <ChatAvatar/>

            <Box my={1} alignItems={'center'}>
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.chatName}
                style={{color: '#333'}}
              >
                {chatName}
              </Typography>

              <Typography
                variant="body1"
                color="textSecondary"
                className={classes.calling}
              >
                00:00
              </Typography>
            </Box>

            <Box my={2} display={'flex'} justifyContent={'center'}>
              <StyledIconButton background={'#F42808'} onClick={endCall}>
                <MdCallEnd/>
              </StyledIconButton>

              <StyledIconButton background={'#858687'}>
                <MdMic/>
              </StyledIconButton>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Call
