import React from "react"
import {makeStyles} from "@material-ui/styles"
import clsx from "clsx"
import {Typography} from "@material-ui/core"
import {useSelector} from "react-redux"
import __ from "../../admin-utils/LanguageHelper"

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    paddingTop: 6,
    paddingBottom: 6,
    width: "100%",
    textAlign: "center",
    background: theme.palette.primary.main
  },
  text: {
    fontSize: 13,
    color: "#fff"
  }
}))

const MessageLine = ({className, title}) => {
  const {myPackage} = useSelector((state) => state.package)
  const classes = useStyles()

  if(myPackage.active === 0) return null

  return (
    <div className={clsx(className, classes.root)}>
      <Typography className={classes.text}>{__('انت تستخدم نسخة تجريبية', 'You are using a trial version')}</Typography>
    </div>
  )
}

export default MessageLine
