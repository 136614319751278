import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {useSnackbar, SnackbarContent} from 'notistack'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import clsx from "clsx"
import {lang} from "../../admin-utils/LanguageHelper"
import {Link as RouterLink, useLocation} from "react-router-dom"

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
    color: theme.palette.common.white,
    flex: 1
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  button: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    textTransform: 'none',
  },
}))

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles()
  const {closeSnackbar} = useSnackbar()
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleDismiss = () => {
    closeSnackbar(props.id)
  }

  // Show only outside chat window
  if(location.pathname.indexOf('/chat') !== -1) return <SnackbarContent ref={ref} className={classes.root} />

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{root: classes.actionRoot}}>
          <Typography variant="subtitle2" className={classes.typography}>{props.message}</Typography>
          <div className={classes.icons}>
            <IconButton
              aria-label="Show more"
              className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon style={{color: 'white'}}/>
            </IconButton>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon style={{color: 'white'}}/>
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography gutterBottom>{props.details}</Typography>
            <Button
              className={classes.button}
              component={RouterLink}
              size="small"
              to={`/chat/${props.thread}`}
            >
              {lang('Open chat', 'عرض المحادثة')}
            </Button>
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  )
})

export default SnackMessage
