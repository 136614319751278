import __, { lang } from "../../admin-utils/LanguageHelper";
import {
  AiOutlineSetting,
  AiOutlineUserAdd,
  BiFile,
  BsBuilding,
  BsFillExclamationSquareFill,
  FiRefreshCcw,
  HiUserGroup,
  ImTree,
  RiFileChartLine,
  RiShieldUserLine
} from "react-icons/all";
import React from "react";

export default [
  {
    // subheader: __('الادارة', 'Administration'),
    items: [
      {
        title: __("الادارة", "Administration"),
        href: "/company",
        icon: RiShieldUserLine,
        items: [
          {
            title: __("ملف المنشأة", "Company Profile"),
            href: "/company/profile",
            icon: BsBuilding
          },
          {
            title: __("الاعدادات العامة", "General Settings"),
            href: "/settings",
            icon: AiOutlineSetting
          },
          {
            title: __("الإقرار الالكتروني", "Electronic Acknowledgment"),
            href: "/company/contracts",
            icon: BiFile
          },
          {
            title: __("الهيكل الوظيفي", "Functional structure"),
            href: "/structure",
            icon: ImTree
          },
          {
            title: __("الموظفين", "Employees"),
            href: "/company/employees",
            icon: HiUserGroup
          },
          {
            title: __("تقارير المنشأة", "Company Reports"),
            href: "/company/reports",
            icon: RiFileChartLine
          },
          {
            title: __("قائمة البلاغات", "Post Reports"),
            href: "/company/post/reports",
            icon: BsFillExclamationSquareFill
          },
          {
            title: __("إضافة مستخدمين", "Add Users"),
            href: "/company/add/users",
            icon: AiOutlineUserAdd
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: __("عن أواصل", "About AWASUL"),
        href: "http://awasul1.com/",
        isExternalLink: true,
        icon: () => (
          <img
            src={"/images/icon.png"}
            width={18}
            style={{ marginRight: lang(15, 0), marginLeft: lang(0, 15) }}
          />
        )
      }
    ]
  },
  {
    items: [
      {
        title: __("ادارة الاشتراك", "Manage Subscription"),
        href: "/subscription",
        icon: FiRefreshCcw
      }
    ]
  }
];
