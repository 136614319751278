import React from "react";
import Lightbox from "lightbox-react";
import { Page } from "../../../admin-utils";

function ImagePreview({ link, filename }) {
  return (
    <Lightbox
      mainSrc={link}
      onCloseRequest={() => Page.hideView()}
      animationDuration={0}
      imageTitle={filename}
    />
  );
}

export default ImagePreview;
