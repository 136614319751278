import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
	followMember: ['member'],
	unFollowMember: ['member'],
	followPost: ['post'],
	unFollowPost: ['post'],
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	members: [],
	posts: []
})

/* ------------- Reducers ------------- */

// Follow member
export const followMember = (state = INITIAL_STATE, {member}) => {
	if (state.members.find(i => i === member)) {
		return {...state, members: state.members}
	}

	return {...state, members: [...state.members, member]}
}

// Unfollow member
export const unFollowMember = (state = INITIAL_STATE, {member}) => {
	let followings = [...state.members]
	return {...state, members: followings.filter(i => i !== member)}
}

// Follow post
export const followPost = (state = INITIAL_STATE, {post}) => {
	if (state.posts.find(i => i === post)) {
		return {...state, posts: state.posts}
	}

	return {...state, posts: [...state.posts, post]}
}

// Unfollow post
export const unFollowPost = (state = INITIAL_STATE, {post}) => {
	let followings = [...state.posts]
	return {...state, posts: followings.filter(i => i !== post)}
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.FOLLOW_MEMBER]: followMember,
	[Types.UN_FOLLOW_MEMBER]: unFollowMember,
	[Types.FOLLOW_POST]: followPost,
	[Types.UN_FOLLOW_POST]: unFollowPost,
})
