import React, { useEffect, useState } from "react";
import {
  Provider as StoreProvider,
  useDispatch,
  useSelector
} from "react-redux";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { getProfile } from "../../views/Login/API";
import AuthActions from "../../redux/reducers/AuthRedux";
import PackageActions from "../../redux/reducers/PackageRedux";
import LoadingOverlay from "react-loading-overlay";
import { GridLoader } from "react-spinners";
import { theme } from "../../theme";
import Pusher from "pusher-js";
import { baseUrl, getQueryParams, subdomain } from "../../utils/globals";
import FollowerAction from "../../redux/reducers/FollowersRedux";
import { useSnackbar } from "notistack";
import _ from "lodash";
import SnackMessage from "./SnackMessage";
import { getData } from "../../admin-utils/API";
import { PusherProvider } from "../../PusherContext";

// Example of user roles: ['GUEST', 'USER', 'ADMIN'];

function AuthGuard({ permission, children }) {
  const session = useSelector(state => state.session);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    user: { member_id },
    token: userToken
  } = useSelector(state => state.session);

  const [loading, setLoading] = useState(true);
  const [pusher, setPusher] = useState(null);

  function pusherAuth(tenant_id, token) {
    const pusher = new Pusher("ff19bf16c77907bce23e", {
      cluster: "eu",
      authEndpoint: `${baseUrl}/pusher/auth`,
      forceTLS: true,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          tenant_id
        }
      }
    });

    setPusher(pusher);
  }

  const getUserProfile = async (tenant_id, queryToken) => {
    const token = queryToken ? queryToken : userToken;
    let userData = await getProfile(token);

    if (!userData) {
      dispatch(AuthActions.authLogin(false, {}, [], ""));
      history.push("/auth/login");
      setLoading(false);
      return;
    }

    setLoading(false);

    // Setup Pusher
    pusherAuth(tenant_id, token);

    // Extract permissions
    let permissions = [];
    userData.role.map(role => {
      role.permissions.map(permission => permissions.push(permission.name));
    });

    dispatch(AuthActions.authLogin(true, userData.user, permissions, token));
  };

  const getTenantId = async () => {
    const data = await getData(
      "domain/check",
      {
        domain: subdomain
      },
      null,
      true
    );

    dispatch(PackageActions.setPackage(data.my_package));
    dispatch(PackageActions.setAvailablePackages(data.packages));

    const { tenant_id } = data;

    if (!tenant_id) {
      return (window.location = "https://awasul1.com");
      // return window.location = 'https://web.awasul.xyz'
    }

    localStorage.setItem("tenant_id", tenant_id);
    return tenant_id;
  };

  useEffect(() => {
    const queryParams = getQueryParams();

    getTenantId().then(tenant_id => {
      if (queryParams.token) {
        getUserProfile(tenant_id, queryParams.token).finally();
      } else {
        if (!session.loggedIn || !session.user) {
          history.push("/auth/login");
          setLoading(false);
          return;
        }

        getUserProfile(tenant_id).finally();
      }
    });

    // if (!session.permissions.includes(permission)) {
    //   history.push('/errors/error-401')
    // }
  }, []);

  useEffect(() => {
    return history.listen(location => {
      if (!pusher) {
        pusherAuth(localStorage.getItem("tenant_id"), userToken);
      }
    });
  }, [history]);

  if (loading) {
    return (
      <LoadingOverlay
        active={loading}
        spinner={<GridLoader color={theme.palette.primary.main} size={10} />}
      >
        <div style={{ height: "100vh" }} />
      </LoadingOverlay>
    );
  }

  return <PusherProvider pusher={pusher}>{children}</PusherProvider>;
}

AuthGuard.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.string.isRequired
};

export default AuthGuard;
