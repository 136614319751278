import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {lang} from "../admin-utils/LanguageHelper"

const MySwal = withReactContent(Swal)

export function confirmDialog({title, onConfirmMessage, onConfirm}) {
  MySwal.fire({
    title,
    showCancelButton: true,
    confirmButtonText: lang('Confirm', 'تأكيد'),
    cancelButtonText: lang('Cancel', 'إلغاء'),
  }).then(async (result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      onConfirm && await onConfirm()

      if (onConfirmMessage) {
        MySwal.fire({
          title: onConfirmMessage,
          showConfirmButton: false,
          timer: 1000,
          icon: 'success'
        }).finally()
      }
    }
  })
}

export function loadingAlert() {
  MySwal.showLoading()
}

export function hideLoadingAlert() {
  MySwal.close()
}

export function successAlert({title}) {
  MySwal.fire({
    title,
    showConfirmButton: false,
    timer: 1000,
    icon: 'success'
  }).finally()
}


export function errorAlert({title}) {
  MySwal.fire({
    title,
    showConfirmButton: false,
    timer: 1500,
    icon: 'error'
  }).finally()
}
