import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import {Typography} from "@material-ui/core";
import __, {lang} from "../../admin-utils/LanguageHelper";
import {IoMdCheckboxOutline, MdCheckBoxOutlineBlank} from "react-icons/all";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ReactHtmlParser from "react-html-parser";
import {getData, postRequest} from "../../admin-utils/API";
import {useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import Lottie from 'react-lottie';
import animationData from './99-bell.json'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  message: {
    fontSize: 15,
    color: "#848484",
    wordBreak: 'break-word'
  },
  content: {
    paddingTop: 0
  },
  mediaArea: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 400,
    backgroundPosition: "initial"
  },
  news: {
    backgroundColor: "#FCF9FC"
  },
  campaign: {
    backgroundColor: "#FCF9FC"
  },
  announcement: {
    backgroundColor: "#FFFDF9"
  },
  share_knowledge: {
    backgroundColor: "#FCF9FC"
  },
  new_hire: {
    backgroundColor: "#FAFCFA"
  },
  alert: {
    backgroundColor: "#D30053",
    color: "#ffffff!important"
  },
  postIcon: {
    marginRight: theme.spacing(2),
    "& img": {
      width: 50,
      height: 50
    }
  },
  withIcon: {
    padding: theme.spacing(2, 3, 2, 3),
    paddingBottom: theme.spacing(1),
    marginRight: -theme.spacing(3),
    marginLeft: -theme.spacing(3)
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 600,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
    backgroundColor: "#fff"
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  confirmContainer: {
    marginRight: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    marginTop: -theme.spacing(2),
    padding: theme.spacing(0, 3, 0, 11),
    paddingBottom: theme.spacing(0)
  },
  checkboxArea: {
    backgroundColor: "white",
    paddingRight: theme.spacing(2)
  },
  checkboxLabel: {
    color: "#D30053"
  },
  checkboxLabelSuccess: {
    color: "#4bb543"
  }
}));

function PostBody({ post, isAlertPopUp, ...rest }) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [is_read, setIsRead] = useState(post.is_read);
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);

  const postType = post.post_type;
  const hasCheck = ["announcement", "new_hire", "campaign", "alert"].includes(
    postType
  );
  const isAlert = postType === "alert";
  const noReport = [
    "announcement",
    "new_hire",
    "campaign",
    "alert",
    "news"
  ].includes(postType);
  const isShareKnowledge = postType === "share_knowledge";

  let readText = lang("Acknowledgement", "تمت القراءة"),
    hasReadText = lang("Acknowledge", "تمت القراءة");

  if (isAlert) {
    readText = lang("Take your precaution", "أخذ الاحتياط");
    hasReadText = lang("Take your precaution", "أخذ الاحتياط");
  }

  let postIcon = null;

  if (postType !== "post") {
    postIcon = `icon_${postType}.svg`;
  }

  const showPostDetails = () => {
    setShowDetails(true);
  };

  useEffect(() => {
    if (showDetails) {
      getComments().finally();
    } else {
      setComments([]);
      setPage(1);
    }
  }, [showDetails]);

  function postView() {
    postRequest(
      "post_view",
      {
        member_id,
        post_id: post.id
      },
      token,
      "post"
    );
  }

  async function getComments() {
    let fetchedComments = await getData(
      "post_comment",
      {
        page,
        orderBy: "created_at",
        sortedBy: "desc",
        search: `post_id:${post.id}`,
        include: "member,reactions,comments,comments.reactions,comments.member"
      },
      token
    );

    let totalPages = fetchedComments.meta.pagination.total_pages;
    let newPosts = [...comments, ...fetchedComments.data];

    setComments(newPosts);

    if (page < totalPages) {
      setPage(page + 1);
    } else {
      setHasMore(false);
    }

    // Add post view
    postView();
  }

  function readPostAction() {
    if (is_read) return;

    postRequest(
      "post_confirm",
      {
        member_id,
        post_id: post.id
      },
      token,
      "post"
    );

    setIsRead(true);
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const PostContent = () => (
    <div
      {...rest}
      className={clsx(
        classes.root,
        classes[postType],
        postIcon && classes.withIcon
      )}
    >
      {postIcon && (isAlert ? (
          <div className={classes.postIcon}>
            <Lottie options={defaultOptions}
                    height={70}
                    width={70}/>
          </div>
      ) : (
          <div className={classes.postIcon}>
            <img src={`/images/${postIcon}`} />
          </div>
      ))}

      <div>
        {post.letter_number && (
          <Typography
            className={clsx(classes[postType])}
            variant="h5"
            color={"textPrimary"}
            component="p"
            gutterBottom
          >
            {`${__("رقم التعميم", "Announcement Number")}: ${
              post.letter_number
            }`}
          </Typography>
        )}

        {post.alert_type_id && (
          <Typography
            className={clsx(classes[postType])}
            variant="h5"
            color={"textPrimary"}
            component="p"
            gutterBottom
          >
            {`${__("نوع التحذير", "Alert Type")}: ${lang(
              post.alert_type.name_en,
              post.alert_type.name_ar
            )}`}
          </Typography>
        )}

        {post.title && (
          <Typography
            className={clsx(classes[postType])}
            variant="h4"
            color={"textPrimary"}
            component="h4"
          >
            {post.title}
          </Typography>
        )}

        <Box maxHeight={isAlertPopUp ? '50vh' : 10000} overflow={isAlertPopUp ? 'scroll' : 'auto'}>
          <Typography
            gutterBottom
            className={clsx(classes.message, classes[postType])}
            variant="body1"
            color={"textSecondary"}
            component="p"
          >
            {ReactHtmlParser(post.description)}
          </Typography>
        </Box>
      </div>
    </div>
  );

  const ConfirmCheckbox = withStyles({
    root: {
      color: "#D30053",
      "&$checked": {
        color: "#4bb543"
      }
    },
    checked: {}
  })(props => <Checkbox color="default" {...props} />);

  return (
    <>
      <PostContent />

      <Box className={clsx(classes[postType], classes.confirmContainer)}>
        {hasCheck && (
          <FormControlLabel
            classes={{
              root: classes.checkboxArea,
              label: is_read
                ? classes.checkboxLabelSuccess
                : classes.checkboxLabel
            }}
            control={
              <ConfirmCheckbox
                checked={is_read}
                onChange={() => readPostAction()}
                icon={<MdCheckBoxOutlineBlank />}
                checkedIcon={<IoMdCheckboxOutline />}
              />
            }
            label={is_read ? hasReadText : readText}
          />
        )}
      </Box>
    </>
  );
}

PostBody.propTypes = {
  post: PropTypes.object.isRequired
};

export default PostBody;
