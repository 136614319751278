import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography
} from "@material-ui/core";
import { MdNotifications, VscMention } from "react-icons/all";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "@material-ui/core/Badge";
import __, { lang } from "../../admin-utils/LanguageHelper";
import { getData, postRequest } from "../../admin-utils/API";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import moment from "moment";
import Divider from "@material-ui/core/Divider";
import { Space } from "../../admin-utils";
import clsx from "clsx";
import { hideLoadingAlert, loadingAlert } from "../../utils/dialogs";
import PusherAction from "../../redux/reducers/RealTimeRedux";

const useStyles = makeStyles(theme => ({
  popover: {
    width: 350
  },
  icon: {
    [theme.breakpoints.down("md")]: {
      color: "#716970"
    }
  },
  headerButton: {
    backgroundColor: "#CFD5EC",
    color: "#fff",
    marginRight: 15,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      fontSize: 22,
      marginRight: 5
    }
  },
  notificationHeader: {
    backgroundColor: "#f5f5f5"
  },
  listItem: {
    alignItems: "flex-start"
  },
  unread: {
    backgroundColor: theme.palette.background.default
  }
}));

const iconsMap = {
  normal: VscMention
};

function Notifications() {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector(state => state.session);

  const dispatch = useDispatch();
  const { notifications: notificationsCount } = useSelector(
    state => state.pusher
  );

  const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: props => props.background,
      fontFamily: "Roboto",
      top: -5,
      right: -5
    }
  }))(Badge);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      getNotifications().finally();
    }
  }, [isOpen]);

  async function getNotifications() {
    setLoading(true);
    let fetchedData = await getData(
      "notification",
      {
        orderBy: "created_at",
        sortedBy: "desc",
        limit: 10,
        type: "normal"
      },
      token
    );

    setNotifications(fetchedData.data);
    setLoading(false);

    dispatch(PusherAction.notification(0, true));
  }

  async function respondInvitation(notification, action) {
    loadingAlert();

    await postRequest(
      `group/${notification.group_id}`,
      {
        action,
        notification: notification.id
      },
      token,
      "post"
    );

    // await getNotifications()

    hideLoadingAlert();
  }

  return (
    <>
      <Tooltip title={lang("Notifications", "التنبيهات")}>
        <IconButton
          ref={ref}
          className={classes.headerButton}
          aria-label="notification"
          onClick={handleOpen}
        >
          <StyledBadge
            background={"#FF6700"}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            badgeContent={notificationsCount}
          >
            <MdNotifications className={classes.icon} />
          </StyledBadge>
        </IconButton>
      </Tooltip>

      <Drawer
        anchor="right"
        classes={{ paper: classes.popover }}
        open={isOpen}
        variant="temporary"
        onClose={handleClose}
      >
        <Box p={2} className={classes.notificationHeader}>
          <Typography variant="h4" color="textPrimary">
            {lang("Notifications", "التنبيهات")}
          </Typography>
        </Box>

        <Divider />

        {loading ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {lang("Loading...", "جاري التحميل...")}
            </Typography>
          </Box>
        ) : notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {lang("There are no notifications", "لا يوجد تنبيهات")}
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.map(notification => {
                return (
                  <ListItem
                    className={clsx(
                      classes.listItem,
                      notification.read === 0 && classes.unread
                    )}
                    component={RouterLink}
                    divider
                    key={notification.id}
                    to="#"
                  >
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                        src={notification.logo}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      // primary={lang(notification.title, notification.title_ar)}
                      // primaryTypographyProps={{variant: 'h5', color: 'textPrimary'}}
                      secondary={
                        <Box my={-0.6}>
                          <Typography
                            variant="body1"
                            component="p"
                            color="textPrimary"
                          >
                            {lang(
                              notification.message,
                              notification.message_ar
                            )}
                          </Typography>

                          <Box display={"flex"}>
                            <div style={{ flex: 1 }}>
                              {moment(notification.created_at).fromNow()}
                            </div>

                            {moment(notification.created_at).format(
                              "DD-MM-YYYY . HH:mm A"
                            )}
                          </Box>

                          {notification.read === 0 && notification.group_id && (
                            <Box display={"flex"} my={1}>
                              <Button
                                fullWidth
                                variant="contained"
                                color={"primary"}
                                onClick={() =>
                                  respondInvitation(notification, "accept")
                                }
                              >
                                {__("قبول", "Accept")}
                              </Button>

                              <Space width={10} />

                              <Button
                                fullWidth
                                variant="outlined"
                                onClick={() =>
                                  respondInvitation(notification, "reject")
                                }
                              >
                                {__("رفض", "Reject")}
                              </Button>
                            </Box>
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}
      </Drawer>
    </>
  );
}

export default Notifications;
