import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/styles'
import {Avatar, Button, Fab} from '@material-ui/core'
import {AiFillCaretDown, AiOutlineGlobal} from "react-icons/all"
import __ from "../admin-utils/LanguageHelper"

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2)
  }
}))

function DirectionToggle({direction, onToggle}) {
  const classes = useStyles()

  return (
    <Button
      className={classes.button}
      onClick={onToggle}
      size={'small'}
      color="primary"
      startIcon={<AiOutlineGlobal style={{top: -2, position: 'relative'}}/>}
    >
      {__('English', 'اللغة العربية')}
    </Button>
  )
}

DirectionToggle.propTypes = {
  direction: PropTypes.string,
  onToggle: PropTypes.func
}

export default DirectionToggle
