import {useState, useEffect} from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'

export default function useAgora(client, withVideo) {
  const [localVideoTrack, setLocalVideoTrack] = useState(undefined)
  const [localAudioTrack, setLocalAudioTrack] = useState(undefined)
  const [joinState, setJoinState] = useState(false)
  const [remoteUsers, setRemoteUsers] = useState([])

  async function createLocalTracks(audioConfig, videoConfig) {
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(audioConfig, videoConfig)
    setLocalAudioTrack(microphoneTrack)
    setLocalVideoTrack(cameraTrack)
    return [microphoneTrack, cameraTrack]
  }

  async function createLocalAudioTrack(audioConfig) {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(audioConfig)
    setLocalAudioTrack(microphoneTrack)
    return [microphoneTrack]
  }

  async function join(appid, channel, token, uid) {
    if (!client) return

    if(withVideo) {
      const [microphoneTrack, cameraTrack] = await createLocalTracks()
      await client.join(appid, channel, token || null)
      await client.publish([microphoneTrack, cameraTrack])
      window.videoTrack = cameraTrack
    }else{
      const [microphoneTrack] = await createLocalAudioTrack()
      await client.join(appid, channel, token || null)
      await client.publish([microphoneTrack])
    }

    window.client = client

    setJoinState(true)
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop()
      localAudioTrack.close()
    }
    if (localVideoTrack) {
      localVideoTrack.stop()
      localVideoTrack.close()
    }
    setRemoteUsers([])
    setJoinState(false)
    // await client.leave()
  }

  useEffect(() => {
    if (!client)
      return
    setRemoteUsers(client.remoteUsers)
    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType)
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserUnpublished = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserJoined = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    const handleUserLeft = (user) => {
      setRemoteUsers(remoteUsers => Array.from(client.remoteUsers))
    }
    client.on('user-published', handleUserPublished)
    client.on('user-unpublished', handleUserUnpublished)
    client.on('user-joined', handleUserJoined)
    client.on('user-left', handleUserLeft)
    return () => {
      client.off('user-published', handleUserPublished)
      client.off('user-unpublished', handleUserUnpublished)
      client.off('user-joined', handleUserJoined)
      client.off('user-left', handleUserLeft)
    }
  }, [client])

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    leave,
    join,
    remoteUsers,
  }
}
