import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Divider, IconButton, Input, Tooltip } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { FormattedMessage } from "react-intl";
import { FiCamera } from "react-icons/all";
import __ from "../../admin-utils/LanguageHelper";
import { postRequest } from "../../admin-utils/API";
import { useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Forms } from "../../admin-utils";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(0, 2),
    borderRadius: 5
  },
  paper: {
    flexGrow: 1
  },
  input: {
    width: "100%",
    border: "none",
    boxShadow: "none!important",
    "& input::placeholder": {
      position: "relative"
    }
  },
  divider: {
    width: 1,
    height: 24
  },
  fileInput: {
    display: "none"
  },
  buttonIcon: {
    color: theme.palette.secondary.main
  },
  loading: {
    marginRight: 10
  }
}));

function CommentForm({ className, post, onCommentAdded, ...rest }) {
  const classes = useStyles();
  const fileInputRef = useRef(null);
  const [message, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [reply_on, setReplyOn] = useState(null);
  const [main_thread, setMainThread] = useState(0);
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);
  const tableRef = useRef();
  let formRef;

  const handleChange = event => {
    event.persist();
    setValue(event.target.value);
  };

  const handleAttach = () => {
    formRef.show();
  };

  const postComment = async () => {
    setLoading(true);

    let params = {
      message,
      post_id: post.id,
      created_by: member_id,
      main_thread,
      include: "member,reactions,comments,comments.reactions,comments.member"
    };

    if (reply_on) {
      params.reply_on = reply_on.id;
    }

    setValue("");

    let request = await postRequest("post_comment", params, token, "post");
    let comment = request.data;

    onCommentAdded(comment.data);
    setLoading(false);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      postComment().finally();
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.paper}>
        <Input
          value={message}
          className={classes.input}
          disableUnderline
          onChange={handleChange}
          onKeyDown={keyPress}
          placeholder={__("أكتب تعليقك", "Write your comment")}
          endAdornment={
            <>
              {loading && (
                <InputAdornment position="end" className={classes.loading}>
                  <CircularProgress size={20} color={"secondary"} />
                </InputAdornment>
              )}
            </>
          }
        />
      </div>

      <Divider className={classes.divider} />
      <Tooltip
        title={
          <FormattedMessage
            id="listing.attach image"
            defaultMessage="Attach image"
          />
        }
      >
        <IconButton edge="end" onClick={handleAttach}>
          <FiCamera className={classes.buttonIcon} />
        </IconButton>
      </Tooltip>

      <Tooltip
        title={
          <FormattedMessage
            id="listing.attach file"
            defaultMessage="Attach file"
          />
        }
      >
        <IconButton edge="end" onClick={handleAttach}>
          <AttachFileIcon className={classes.buttonIcon} />
        </IconButton>
      </Tooltip>

      <Forms
        hide
        onRef={ref => (formRef = ref)}
        title={__("اضافة تعليق", "New Comment")}
        buttons={["saveExit", "cancel"]}
        saveExitTitle={__("اضافة التعليق", "Add Comment")}
        endpoint={"post_comment"}
        hidden={{
          created_by: member_id,
          main_thread,
          include:
            "member,reactions,comments,comments.reactions,comments.member",
          post_id: post.id
        }}
        defaultValues={{
          message
        }}
        fields={[
          {
            title: __("أكتب تعليقك", "Write your comment"),
            field: "message",
            type: "text"
          },
          {
            field: "gallery",
            type: "file",
            multiple: true,
            size: 5000
          }
        ]}
        onFinish={comment => {
          setValue("");
          onCommentAdded(comment);
        }}
      />
    </div>
  );
}

CommentForm.propTypes = {
  className: PropTypes.string
};

export default CommentForm;
