import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setPusherData: ['pusherData'],
  chatNotification: ['count', 'action'],
  notification: ['count', 'action']
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  pusherData: null,
  chatNotifications: 0,
  notifications: 0
})

/* ------------- Reducers ------------- */
export const setPusherData = (state = INITIAL_STATE, {pusherData}) => {
  return {...state, pusherData}
}

export const chatNotification = (state = INITIAL_STATE, {count = 1, action = 'update'}) => {
  let newCount = count
  if(action === 'add') {
    newCount = state.chatNotifications + 1
  }

  return {...state, chatNotifications: newCount}
}

export const notification = (state = INITIAL_STATE, {count = 1, action = 'update'}) => {
  let newCount = count
  if(action === 'add') {
    newCount = state.notifications + 1
  }

  return {...state, notifications: newCount}
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PUSHER_DATA]: setPusherData,
  [Types.CHAT_NOTIFICATION]: chatNotification,
  [Types.NOTIFICATION]: notification,
})
