import {createReducer, createActions} from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const {Types, Creators} = createActions({
  setPackage: ['myPackage'],
  setAvailablePackages: ['availablePackages'],
})

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
	myPackage: {},
  availablePackages: []
})

/* ------------- Reducers ------------- */

export const setPackage = (state = INITIAL_STATE, {myPackage}) => {
	return {...state, myPackage}
}

export const setAvailablePackages = (state = INITIAL_STATE, {availablePackages}) => {
	return {...state, availablePackages}
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
	[Types.SET_PACKAGE]: setPackage,
	[Types.SET_AVAILABLE_PACKAGES]: setAvailablePackages,
})
