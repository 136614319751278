import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import { Avatar, IconButton, Link, Typography } from "@material-ui/core";
import __, { lang } from "../../admin-utils/LanguageHelper";
import { HiDotsVertical, RiDeleteBin6Line } from "react-icons/all";
import DropdownMenu from "../DropdownMenu";
import { Forms } from "../../admin-utils";
import { useSelector } from "react-redux";
import { confirmDialog } from "../../utils/dialogs";
import { postRequest } from "../../admin-utils/API";
import FilesGallery from "./FilesGallery";
import _ from "lodash";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  bubble: {
    flexGrow: 1,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    borderRadius: 8,
    marginTop: -10,
    backgroundColor: theme.palette.background.default
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  footerItem: {
    marginRight: theme.spacing(2),
    cursor: "pointer"
  },
  message: {
    color: "#848484"
  },
  footer: {
    display: "flex",
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  mediaContainer: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: "auto",
    color: theme.palette.secondary.main
  }
}));

function CommentBubble({
  post,
  comment: originalComment,
  onReply,
  className,
  isShareKnowledge = false,
  ...rest
}) {
  const classes = useStyles();
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);
  const [anchorEl, setAnchorEl] = useState(null);
  const [comment, setComment] = useState(originalComment);

  const [isLiked, setIsLiked] = useState(false);
  const [myReaction, setMyReaction] = useState([]);

  let formRef;

  useEffect(() => {
    if (comment) {
      let reactions = comment.reactions;
      let myCommentReaction = reactions
        ? reactions.find(i => i.created_by === member_id)
        : [];
      setMyReaction(myCommentReaction);

      setIsLiked(comment.isLiked);
    }
  }, [comment]);

  if (!comment || !comment.member) return null;

  const openDropdownMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Delete post action
  const handleDeleteComment = () => {
    confirmDialog({
      title: lang(
        "Are you sure you want to delete this comment?",
        "هل انت متأكد انك تريد حذف التعليق؟"
      ),
      onConfirm: () => {
        deleteComment().finally();
      },
      onConfirmMessage: lang("Comment has been deleted", "تم حذف التعليق")
    });
  };

  const deleteComment = async () => {
    await postRequest(`post_comment/${comment.id}`, {}, token, "delete");
    setComment(null);
  };

  const handleLikeComment = async () => {
    let method = "post",
      endpoint = "comment_reaction";

    if (myReaction) {
      method = "delete";
      endpoint = `comment_reaction/${myReaction.id}`;
    }

    setIsLiked(!isLiked);

    let response = await postRequest(
      endpoint,
      {
        reaction: "like",
        created_by: member_id,
        comment_id: comment.id
      },
      token,
      method
    );

    console.log(response);

    setMyReaction(isLiked ? null : response.data.data);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        className={classes.avatar}
        alt={comment.member.name}
        component={RouterLink}
        src={comment.member.image}
        to={`/profile/${comment.member.username}`}
      />

      <Box>
        <div className={classes.bubble}>
          <div className={classes.header}>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/profile/${comment.member.username}`}
              variant="h6"
              style={{ fontFamily: "Roboto" }}
            >
              {comment.member.name}
            </Link>

            <IconButton
              onClick={openDropdownMenu}
              className={classes.moreButton}
              size="small"
            >
              <HiDotsVertical size={20} />
            </IconButton>
          </div>

          <Typography
            className={classes.message}
            variant="body1"
            color={"textSecondary"}
            gutterBottom
          >
            {comment.message}
          </Typography>
        </div>

        <Box className={classes.mediaContainer}>
          {comment.gallery.media.length > 0 && (
            <FilesGallery
              small
              images={
                _.isString(comment.gallery)
                  ? [comment.gallery]
                  : comment.gallery
              }
            />
          )}
        </Box>

        <div className={classes.footer}>
          <Box display={"flex"}>
            {!isShareKnowledge && (
              <>
                <Link
                  className={classes.footerItem}
                  color={isLiked ? "primary" : "textSecondary"}
                  variant="body2"
                  onClick={handleLikeComment}
                >
                  {__("إعجاب", "Like")}
                </Link>

                {comment.main_thread == 0 && (
                  <Link
                    className={classes.footerItem}
                    color="textSecondary"
                    variant="body2"
                    onClick={() => {
                      formRef.show();
                    }}
                  >
                    {__("رد", "Reply")}
                  </Link>
                )}
              </>
            )}
          </Box>

          <Typography className={classes.footerItem} variant="body2">
            {moment(comment.created_at).fromNow()}
          </Typography>
        </div>

        {comment.comments && comment.comments.length > 0 && (
          <div style={{ marginTop: 10, padding: 10 }}>
            <div className={classes.comments}>
              {comment.comments.map(reply => (
                <CommentBubble post={post} comment={reply} key={reply.id} />
              ))}
            </div>
          </div>
        )}
      </Box>

      <Forms
        hide
        onRef={ref => (formRef = ref)}
        title={`${lang("Reply on", "الرد على")}: ${comment.member.name}`}
        endpoint={"post_comment"}
        buttons={["cancel", "saveExit"]}
        saveExitTitle={lang("Send", "ارسال")}
        hidden={{
          post_id: post.id,
          created_by: member_id,
          main_thread: comment.id,
          include:
            "member,reactions,comments,comments.reactions,comments.member"
        }}
        fields={[
          {
            title: __("اكتب رد", "Write comment"),
            field: "message",
            type: "text",
            rules: ["required"]
          }
        ]}
        onFinish={data => {
          // onReply && onReply(data);

          let comments = comment.comments;
          if (comments) {
            comments = [data, ...comment.comments];
          } else {
            comments = [data];
          }

          setComment({
            ...comment,
            comments
          });
        }}
      />

      <DropdownMenu
        small
        withArrow={false}
        handleClose={handleClose}
        anchorEl={anchorEl}
        items={[
          {
            title: `${__("تعليق", "Comment")} #${comment.id}`
          },
          {
            hide: +comment.created_by !== member_id,
            title: __("حذف", "Delete"),
            icon: RiDeleteBin6Line,
            onClick: () => {
              handleDeleteComment();
            }
          }
        ]}
      />
    </div>
  );
}

CommentBubble.propTypes = {
  className: PropTypes.string,
  comment: PropTypes.object.isRequired
};

export default CommentBubble;
