import apisauce from 'apisauce'
import {baseUrl, subdomain} from '../../utils/globals'

const api = apisauce.create({
  baseURL: baseUrl,
  headers: {
    "Cache-Control": "no-cache"
  },
  timeout: 10000
})

const getProfile = async (token) => {
  const tenant_id = localStorage.getItem('tenant_id')
  const userRequest = await api.get('user/profile', {
    include: 'roles',
    tenant_id
  }, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    }
  })

  if (userRequest.status === "error") {
    return false
  }

  let user = userRequest.data.data

  if (!user) return false

  let userData = {
    user: {
      id: user.id,
      name: user.member.name,
      username: user.member.username,
      email: user.email,
      member: user.member,
      member_id: user.member.id,
      image: user.member.image
    },
    role: user.roles,
    token,
  }

  if (!userData.role) {
    return false
  }

  return userData
}

const checkLogin = async (data) => {
  const tenant_id = localStorage.getItem('tenant_id')
  const response = await api.post('oauth/token', {
    ...data,
    tenant_id,
    client_id: 2,
    client_secret: 'RYcpMUeSytqafrMJF48Tko0VOazXSxj8ACBIqs8t',
    grant_type: 'password',
    scope: ''
  })

  let responseData = response.data

  if (!responseData) {
    return false
  }

  if (responseData.error) {
    return false
  }

  return getProfile(responseData.access_token).finally()
}

const logout = async (endpoint, data) => {
  return await api.post(endpoint, data)
}

export {
  getProfile,
  checkLogin,
  logout
}
