import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import "./styles.scss";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import { usePusher } from "../../PusherContext";
import PusherAction, { notification } from "../../redux/reducers/RealTimeRedux";
import { getData } from "../../admin-utils/API";
import { useSnackbar } from "notistack";
import SnackMessage from "../../components/AuthGuard/SnackMessage";
import { lang } from "../../admin-utils/LanguageHelper";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";
import { Page } from "../../admin-utils";
import FilePreview from "../../components/FileItem/FilePreview";
import PostCard from "../../components/PostCard";
import { Howl } from "howler";

const savedDirection = localStorage.getItem("direction");
document.body.style.direction = savedDirection;

const useStyles = makeStyles(theme => ({
  container: {
    height: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0 // IE11 fix
    }
  },
  content: {
    // paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256
    }
    // [theme.breakpoints.down("xs")]: {
    //   paddingTop: 56
    // }
  }
}));

function Dashboard({ route }) {
  const history = useHistory();
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);
  const { myPackage } = useSelector(state => state.package);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const dispatch = useDispatch();

  const pusher = usePusher();
  const tenant_id = localStorage.getItem("tenant_id");

  useEffect(() => {
    function postUpdateCallback(data) {
      dispatch(PusherAction.setPusherData(data));
    }

    function updateNotificationsCount(data) {
      console.log(data);

      if (data.type === "chat") {
        if (location.pathname.indexOf("/chat") === -1) {
          dispatch(PusherAction.chatNotification(1, "add"));
        }

        const sender = data.sender;
        const title = lang(
          `${sender} sent a new message`,
          `ارسل ${sender} رسالة جديدة`
        );

        enqueueSnackbar(data.message, {
          preventDuplicate: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center"
          },
          content: (key, message) => (
            <SnackMessage
              id={key}
              message={title}
              details={message}
              thread={data.id}
            />
          )
        });
      }

      if (data.type === "alert" || data.type === "campaign") {
        dispatch(PusherAction.notification(1, "add"));
        getAlert(data.id, data.type).finally();
      }

      if (
        data.type === "reaction" ||
        data.type === "comment" ||
        data.type === "follow" ||
        data.type === "group" ||
        data.type === "project" ||
        data.type === "task" ||
        data.type === "post" ||
        data.type === "announcement" ||
        data.type === "contract" ||
        data.type === "file"
      ) {
        dispatch(PusherAction.notification(1, "add"));
      }
    }

    if (pusher) {
      const pusherConnected = localStorage.getItem("pusherConnected");
      if (pusherConnected !== "CONNECTED") {
        const realtimeChannel = pusher.subscribe(
          `private-realtime-channel-${tenant_id}`
        );
        realtimeChannel.bind("client-post-update", postUpdateCallback);
        realtimeChannel.bind(
          `client-notification-${tenant_id}-${member_id}`,
          updateNotificationsCount
        );

        localStorage.setItem("pusherConnected", "CONNECTED");
      }
    }
  }, [pusher]);

  useEffect(() => {
    getNotifications().finally();
  }, []);

  useEffect(() => {
    // Check active package
    if (
      myPackage.active === 0 &&
      location.pathname.indexOf("/subscription/plans") === -1
    ) {
      history.push("/subscription/plans");
    }
  }, [location]);

  async function getAlert(id, type) {
    const sound = new Howl({
      src: "/sound/alert.mp3"
    });

    const fetchedPosts = await getData(
      `post/${id}`,
      {
        include: "creator,comments,comments.member,reactions,alert_type"
      },
      token
    );

    if (type === "alert") {
      sound.play();
    }

    Page.pushView({
      stay: true,
      modal: true,
      clear: true,
      drawerWidth: 600,
      direction: "right",
      view: <PostCard post={fetchedPosts.data} isAlert />
    });
  }

  async function getNotifications() {
    let counts = await getData("notification/count", {}, token);

    dispatch(PusherAction.notification(counts.normal));
    dispatch(PusherAction.chatNotification(counts.chat));
  }

  return (
    <>
      <TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />

      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
      />
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
