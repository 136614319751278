import palette from './palette';

export default {
   fontFamily: 'Dubai W23!important',
   fontWeightRegular: 'normal',
   h1: {
      color: palette.text.primary,
      fontWeight: 'bold',
      fontSize: '35px',
      letterSpacing: '-0.24px',
      lineHeight: '40px',
   },
   h2: {
      color: palette.text.white,
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: '32px',
   },
   h3: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '24px',
      letterSpacing: '-0.06px',
      lineHeight: '28px',
   },
   h4: {
      color: palette.text.primary,
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '-0.06px',
      lineHeight: '24px',
   },
   h5: {
      color: palette.text.primary,
      fontWeight: 'bold',
      fontSize: '15px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
      position: 'relative',
   },
   h6: {
      color: palette.text.primary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '20px',
   },
   subtitle1: {
      color: palette.text.primary,
      fontSize: '16px',
      letterSpacing: '-0.05px',
      lineHeight: '25px',
      marginTop: 5,
   },
   subtitle2: {
      color: palette.text.secondary,
      fontWeight: 400,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
      marginTop: 5,
   },
   body1: {
      color: palette.text.secondary,
      fontSize: '14px',
      letterSpacing: '-0.05px',
      lineHeight: '21px',
   },
   body2: {
      color: palette.text.secondary,
      fontSize: '12px',
      letterSpacing: '-0.04px',
      lineHeight: '18px',
   },
   button: {
      color: palette.text.primary,
      fontSize: '14px',
   },
   caption: {
      color: palette.text.secondary,
      fontSize: '11px',
      letterSpacing: '0.33px',
      lineHeight: '13px',
   },
   overline: {
      color: palette.text.secondary,
      fontSize: '12px',
      fontWeight: 500,
      letterSpacing: '0.33px',
      lineHeight: '13px',
      textTransform: 'uppercase',
   },
};
