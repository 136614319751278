import React, {useRef, useEffect} from "react"

const MediaPlayer = (props) => {
  const container = useRef(null)
  useEffect(() => {
    let _a
    if (!container.current)
      return;
    (_a = props.videoTrack) === null || _a === void 0 ? void 0 : _a.play(container.current)
    return () => {
      let _a;
      (_a = props.videoTrack) === null || _a === void 0 ? void 0 : _a.stop()
    }
  }, [container, props.videoTrack])
  useEffect(() => {
    let _a;
    (_a = props.audioTrack) === null || _a === void 0 ? void 0 : _a.play()
    return () => {
      let _a;
      (_a = props.audioTrack) === null || _a === void 0 ? void 0 : _a.stop()
    }
  }, [props.audioTrack])

  return (React.createElement("div", {ref: container, ...props}))
}

export default MediaPlayer
