import {colors} from '@material-ui/core'

const white = '#FFFFFF'

export default {
  primary: {
    contrastText: white,
    dark: '#7e0673',
    main: '#AA089B',
    light: '#cf0abd'
  },
  secondary: {
    contrastText: white,
    dark: '#6b7692',
    main: '#8997BB',
    light: '#a5b5e2'
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#3F414D',
    secondary: '#8A98BA',
    link: colors.blue[600],
    white,
  },
  link: colors.blue[800],
  icon: '#8A98BA',
  background: {
    default: '#f4f6f8',
    dark: '#f4f6f8',
    darker: '#cdcfd0',
    paper: white
  },
  divider: colors.grey[200],
  devBar: 'transparent',
}
