import React, {useEffect, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Box,
  IconButton,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core'
import {FiEdit} from "react-icons/all"
import MediaPlayer from "./MediaPlayer"
import {Switch, Case, If} from "react-if"

const useStyles = makeStyles((theme) => ({
  localVideo: {
    width: 140,
    height: 170,
    position: 'absolute',
    left: 20,
    top: 20,
    zIndex: 1,
    borderRadius: 10,
    overflow: 'hidden',
  },
  remoteSingle: {
    borderRadius: 5,
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: 'black',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
}))

const windowSize = {
  width: 500,
  height: 480
}

function VideoStream({videoTrack, audioTrack, remoteUsers, withVideo}) {
  const classes = useStyles()
  const usersCount = remoteUsers.length

  const [remoteUser, setRemoteUser] = useState()
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if(remoteUsers.length === 1) {
      let remoteUser = remoteUsers[0]
      setRemoteUser(remoteUser)

      if(remoteUser.videoTrack) {
        let vWidth = remoteUser.videoTrack._videoWidth
        let vHeight = remoteUser.videoTrack._videoHeight

        if(height > windowSize.height) {
          vWidth = (width * windowSize.height) / height
          vHeight = windowSize.height
        }

        setWidth(vWidth)
        setHeight(vHeight)
        setReady(true)
      }
    }

  }, [remoteUsers])

  return (
    <>
      {withVideo && <MediaPlayer
        className={classes.localVideo}
        videoTrack={videoTrack}
        audioTrack={audioTrack}
      />}

      {ready && <>
        {usersCount > 0 && <Switch>
          <Case condition={usersCount === 1}>
            <Box className={classes.remoteSingle}>
              <MediaPlayer
                style={{width, height}}
                videoTrack={remoteUser.videoTrack}
                audioTrack={remoteUser.audioTrack}
              />
            </Box>

          </Case>
          <Case condition={usersCount > 1}>
            {remoteUsers.map(user => (<div className='remote-player-wrapper' key={user.uid}>
              <MediaPlayer
                className={classes.localVideo}
                videoTrack={user.videoTrack}
                audioTrack={user.audioTrack}
              />
            </div>))}
          </Case>
        </Switch>}
      </>}
    </>
  )
}

export default VideoStream
