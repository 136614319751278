import React, {Component} from "react"
import clsx from "clsx"
import Call from "./index"
import Modal from "@material-ui/core/Modal"
import withStyles from "@material-ui/core/styles/withStyles"
import {makeStyles} from "@material-ui/core"

const useStyles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: 'calc(100% - 120px)',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    marginTop: 120
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 300,
    height: 400,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2)
  },
  videoModal: {
    width: 700,
    height: 500,
  }
})

const initialState = {
  showCallModal: false,
  members: [],
  withVideo: false
}

class CallModal extends Component {

  state = initialState

  static callModalInstance

  static show(params) {
    this.callModalInstance.showModal(params)
  }

  static hide() {
    this.callModalInstance.hideModal()
  }

  showModal(params) {
    this.setState({showCallModal: true, ...params})
  }

  hideModal() {
    this.setState(initialState)
  }

  render() {
    const {showCallModal, members, withVideo, incomingCall} = this.state
    const {classes} = this.props

    return (
      <Modal open={showCallModal}>
        <div className={clsx(classes.modal, withVideo && classes.videoModal)}>
          <Call
            members={members}
            onClose={() => this.setState({showCallModal: false})}
            withVideo={withVideo}
            isIncomingCall={incomingCall}
          />
        </div>
      </Modal>
    )
  }
}

export default withStyles(useStyles)(CallModal)
