import React from "react";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { CardHeader, Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  audioContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .video-react": {
      maxWidth: 800
    }
  }
}));

function AudioPreview({ link, filename }) {
  const classes = useStyles();

  return (
    <Box>
      <CardHeader title={filename} />

      <Divider />

      <Box className={classes.audioContainer} style={{ direction: "ltr" }}>
        <AudioPlayer
          autoPlay
          src={link}
          onPlay={e => console.log("onPlay")}
          // other props here
        />
      </Box>
    </Box>
  );
}

export default AudioPreview;
