/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import ErrorLayout from "./layouts/Error";
import DashboardLayout from "./layouts/Dashboard";

export default [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/my/company" />
  },
  {
    path: "/auth",
    component: AuthLayout,
    routes: [
      {
        path: "/auth/login",
        exact: true,
        component: lazy(() => import("src/views/Login"))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: "/errors",
    component: ErrorLayout,
    routes: [
      {
        path: "/errors/error-401",
        exact: true,
        component: lazy(() => import("src/views/Error401"))
      },
      {
        path: "/errors/error-404",
        exact: true,
        component: lazy(() => import("src/views/Error404"))
      },
      {
        path: "/errors/error-500",
        exact: true,
        component: lazy(() => import("src/views/Error500"))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: "*",
    component: DashboardLayout,
    routes: [
      /* START_ROUTES */ {
        path: "/dashboard",
        exact: true,
        component: lazy(() => import("src/views/Dashboard"))
      },
      {
        path: "/dashboard/:id",
        exact: true,
        component: lazy(() => import("src/views/Dashboard/DashboardDetails"))
      },
      {
        path: "/my/company",
        exact: true,
        component: lazy(() => import("src/views/MyCompany"))
      },
      {
        path: "/subscription",
        exact: true,
        component: lazy(() => import("src/views/MyCompany/Subscription"))
      },
      {
        path: "/subscription/plans",
        exact: true,
        component: lazy(() => import("src/views/MyCompany/Plans"))
      },
      {
        path: "/my/company/:id",
        exact: true,
        component: lazy(() => import("src/views/MyCompany/MyCompanyDetails"))
      },
      {
        path: "/community",
        exact: true,
        component: lazy(() => import("src/views/Community"))
      },
      {
        path: "/community/:id",
        exact: true,
        component: lazy(() => import("src/views/Community/CommunityDetails"))
      },
      {
        path: "/activities",
        exact: true,
        component: lazy(() => import("src/views/Activities"))
      },
      {
        path: "/activities/:id",
        exact: true,
        component: lazy(() => import("src/views/Activities/ActivitiesDetails"))
      },
      {
        path: "/structure",
        exact: true,
        component: lazy(() => import("src/views/Structure"))
      },
      {
        path: "/structure/:type",
        exact: true,
        component: lazy(() => import("src/views/Structure"))
      },
      {
        path: "/chat",
        exact: true,
        component: lazy(() => import("src/views/Chat"))
      },
      {
        path: "/chat/:id",
        exact: true,
        component: lazy(() => import("src/views/Chat"))
      },
      {
        path: "/meeting",
        exact: true,
        component: lazy(() => import("src/views/Meeting"))
      },
      {
        path: "/meeting/:id",
        exact: true,
        component: lazy(() => import("src/views/Meeting/MeetingDetails"))
      },
      {
        path: "/groups",
        exact: true,
        component: lazy(() => import("src/views/Groups"))
      },
      {
        path: "/groups/:id",
        exact: true,
        component: lazy(() => import("src/views/Groups/GroupsDetails"))
      },
      {
        path: "/group/rate",
        exact: true,
        component: lazy(() => import("src/views/GroupRate"))
      },
      {
        path: "/group/rate/:id",
        exact: true,
        component: lazy(() => import("src/views/GroupRate/GroupRateDetails"))
      },
      {
        path: "/group/invitations",
        exact: true,
        component: lazy(() => import("src/views/GroupInvitations"))
      },
      {
        path: "/group/invitations/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/GroupInvitations/GroupInvitationsDetails")
        )
      },
      {
        path: "/tasks",
        exact: true,
        component: lazy(() => import("src/views/Tasks"))
      },
      {
        path: "/tasks/:id",
        exact: true,
        component: lazy(() => import("src/views/Tasks/TasksDetails"))
      },
      {
        path: "/calendar",
        exact: true,
        component: lazy(() => import("src/views/Calendar"))
      },
      {
        path: "/calendar/:id",
        exact: true,
        component: lazy(() => import("src/views/Calendar/CalendarDetails"))
      },
      {
        path: "/drive/:id?",
        exact: true,
        component: lazy(() => import("src/views/Drive"))
      },
      {
        path: "/company/calendar",
        exact: true,
        component: lazy(() => import("src/views/CompanyCalendar"))
      },
      {
        path: "/company/calendar/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyCalendar/CompanyCalendarDetails")
        )
      },
      {
        path: "/projects",
        exact: true,
        component: lazy(() => import("src/views/Projects"))
      },
      {
        path: "/projects/:id",
        exact: true,
        component: lazy(() => import("src/views/Projects/ProjectsDetails"))
      },
      {
        path: "/project/reports/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/Projects/ProjectReportsDetails")
        )
      },
      {
        path: "/campaigns",
        exact: true,
        component: lazy(() => import("src/views/Campaigns"))
      },
      {
        path: "/campaigns/:id",
        exact: true,
        component: lazy(() => import("src/views/Campaigns/CampaignsDetails"))
      },
      {
        path: "/announcements",
        exact: true,
        component: lazy(() => import("src/views/Announcements"))
      },
      {
        path: "/announcements/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/Announcements/AnnouncementsDetails")
        )
      },
      {
        path: "/alerts",
        exact: true,
        component: lazy(() => import("src/views/Alerts"))
      },
      {
        path: "/alerts/:id",
        exact: true,
        component: lazy(() => import("src/views/Alerts/AlertsDetails"))
      },
      {
        path: "/share/knowledge",
        exact: true,
        component: lazy(() => import("src/views/ShareKnowledge"))
      },
      {
        path: "/share/knowledge/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/ShareKnowledge/ShareKnowledgeDetails")
        )
      },
      {
        path: "/company/contracts",
        exact: true,
        component: lazy(() => import("src/views/CompanyContracts"))
      },
      {
        path: "/company/contracts/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyContracts/CompanyContractsDetails")
        )
      },
      {
        path: "/settings",
        exact: true,
        component: lazy(() => import("src/views/Settings"))
      },
      {
        path: "/settings/:id",
        exact: true,
        component: lazy(() => import("src/views/Settings/SettingsDetails"))
      },
      {
        path: "/company/profile",
        exact: true,
        component: lazy(() => import("src/views/CompanyProfile"))
      },
      {
        path: "/company/profile/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyProfile/CompanyProfileDetails")
        )
      },
      {
        path: "/company/profile/colors",
        exact: true,
        component: lazy(() => import("src/views/CompanyProfileColors"))
      },
      {
        path: "/company/profile/colors/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyProfileColors/CompanyProfileColorsDetails")
        )
      },
      {
        path: "/company/cities",
        exact: true,
        component: lazy(() => import("src/views/CompanyCities"))
      },
      {
        path: "/company/cities/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyCities/CompanyCitiesDetails")
        )
      },
      {
        path: "/company/employees",
        exact: true,
        component: lazy(() => import("src/views/CompanyEmployees"))
      },
      {
        path: "/edit-account",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyEmployees/CompanyEmployeesDetails")
        )
      },
      {
        path: "/company/directory",
        exact: true,
        component: lazy(() => import("src/views/CompanyDirectory"))
      },
      {
        path: "/company/directory/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyDirectory/CompanyDirectoryDetails")
        )
      },
      {
        path: "/company/reports",
        exact: true,
        component: lazy(() => import("src/views/CompanyReports"))
      },
      {
        path: "/company/reports/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyReports/CompanyReportsDetails")
        )
      },
      {
        path: "/company/reports/attendance",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsAttendance"))
      },
      {
        path: "/company/reports/attendance/:id",
        exact: true,
        component: lazy(() =>
          import(
            "src/views/CompanyReportsAttendance/CompanyReportsAttendanceDetails"
          )
        )
      },
      {
        path: "/company/reports/confirm/reading",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsConfirmReading"))
      },
      {
        path: "/company/reports/confirm/reading/:id",
        exact: true,
        component: lazy(() =>
          import(
            "src/views/CompanyReportsConfirmReading/CompanyReportsConfirmReadingDetails"
          )
        )
      },
      {
        path: "/company/reports/posts",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsPosts"))
      },
      {
        path: "/company/reports/posts/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyReportsPosts/CompanyReportsPostsDetails")
        )
      },
      {
        path: "/company/reports/announcements",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsAnnouncements"))
      },
      {
        path: "/company/reports/announcements/:id",
        exact: true,
        component: lazy(() =>
          import(
            "src/views/CompanyReportsAnnouncements/CompanyReportsAnnouncementsDetails"
          )
        )
      },
      {
        path: "/company/reports/campaigns",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsCampaigns"))
      },
      {
        path: "/company/reports/campaigns/:id",
        exact: true,
        component: lazy(() =>
          import(
            "src/views/CompanyReportsCampaigns/CompanyReportsCampaignsDetails"
          )
        )
      },
      {
        path: "/company/reports/alerts",
        exact: true,
        component: lazy(() => import("src/views/CompanyReportsAlerts"))
      },
      {
        path: "/company/reports/alerts/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyReportsAlerts/CompanyReportsAlertsDetails")
        )
      },
      {
        path: "/company/post/reports",
        exact: true,
        component: lazy(() => import("src/views/CompanyPostReports"))
      },
      {
        path: "/company/post/reports/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyPostReports/CompanyPostReportsDetails")
        )
      },
      {
        path: "/company/add/users",
        exact: true,
        component: lazy(() => import("src/views/CompanyAddUsers"))
      },
      {
        path: "/company/add/users/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/CompanyAddUsers/CompanyAddUsersDetails")
        )
      },
      {
        path: "/awasul",
        exact: true,
        component: lazy(() => import("src/views/Awasul"))
      },
      {
        path: "/awasul/:id",
        exact: true,
        component: lazy(() => import("src/views/Awasul/AwasulDetails"))
      },
      {
        path: "all/projects/reports",
        exact: true,
        component: lazy(() => import("src/views/AllProjectsReports"))
      },
      {
        path: "all/projects/reports/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/AllProjectsReports/AllProjectsReportsDetails")
        )
      },
      {
        path: "/form/alert",
        exact: true,
        component: lazy(() => import("src/views/FormAlert"))
      },
      {
        path: "/form/alert/:id",
        exact: true,
        component: lazy(() => import("src/views/FormAlert/FormAlertDetails"))
      },
      {
        path: "/alert/type",
        exact: true,
        component: lazy(() => import("src/views/AlertType"))
      },
      {
        path: "/alert/type/:id",
        exact: true,
        component: lazy(() => import("src/views/AlertType/AlertTypeDetails"))
      },
      {
        path: "/tags",
        exact: true,
        component: lazy(() => import("src/views/Tags"))
      },
      {
        path: "/tags/:id",
        exact: true,
        component: lazy(() => import("src/views/Tags/TagsDetails"))
      },
      {
        path: "/notification",
        exact: true,
        component: lazy(() => import("src/views/Notification"))
      },
      {
        path: "/notification/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/Notification/NotificationDetails")
        )
      },
      {
        path: "/profile",
        exact: true,
        component: lazy(() => import("src/views/MyProfile"))
      },
      {
        path: "/profile/:username",
        exact: true,
        component: lazy(() => import("src/views/MyProfile"))
      },
      {
        path: "/my/friends",
        exact: true,
        component: lazy(() => import("src/views/MyFriends"))
      },
      {
        path: "/my/friends/:id",
        exact: true,
        component: lazy(() => import("src/views/MyFriends/MyFriendsDetails"))
      },
      {
        path: "/my/account",
        exact: true,
        component: lazy(() => import("src/views/MyAccount"))
      },
      {
        path: "/my/account/:id",
        exact: true,
        component: lazy(() => import("src/views/MyAccount/MyAccountDetails"))
      },
      {
        path: "/change/password",
        exact: true,
        component: lazy(() => import("src/views/ChangePassword"))
      },
      {
        path: "/change/password/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/ChangePassword/ChangePasswordDetails")
        )
      },
      {
        path: "/header/search",
        exact: true,
        component: lazy(() => import("src/views/HeaderSearch"))
      },
      {
        path: "/header/search/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/HeaderSearch/HeaderSearchDetails")
        )
      },
      {
        path: "/user/management/roles",
        exact: true,
        component: lazy(() => import("src/views/UserManagementRoles"))
      },
      {
        path: "/user/management/roles/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/UserManagementRoles/UserManagementRolesDetails")
        )
      },
      {
        path: "/user/management/users",
        exact: true,
        component: lazy(() => import("src/views/UserManagementUsers"))
      },
      {
        path: "/user/management/users/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/UserManagementUsers/UserManagementUsersDetails")
        )
      },
      {
        path: "/employee/contracts",
        exact: true,
        component: lazy(() => import("src/views/EmployeeContracts"))
      },
      {
        path: "/employee/contracts/:id",
        exact: true,
        component: lazy(() =>
          import("src/views/EmployeeContracts/EmployeeContractsDetails")
        )
      },
      /* END_ROUTES */
      {
        path: "/news",
        exact: true,
        component: lazy(() => import("src/views/News"))
      },
      {
        path: "/news/:id",
        exact: true,
        component: lazy(() => import("src/views/News/NewsDetails"))
      },
      {
        path: "/feed/:type",
        exact: true,
        component: lazy(() => import("src/views/Feed"))
      },
      {
        path: "/logout",
        exact: true,
        component: lazy(() => import("src/views/Logout"))
      },

      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
