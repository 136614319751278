import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import {
  Avatar,
  Button,
  CardHeader,
  IconButton,
  Link,
  Typography
} from "@material-ui/core";
import __, { lang } from "../../admin-utils/LanguageHelper";
import {
  GoPrimitiveDot,
  HiDotsVertical,
  HiOutlineArrowNarrowLeft,
  RiDeleteBin6Line,
  VscEdit
} from "react-icons/all";
import DropdownMenu from "../DropdownMenu";
import { useDispatch, useSelector } from "react-redux";
import FollowerAction from "../../redux/reducers/FollowersRedux";
import { getData, postRequest } from "../../admin-utils/API";
import { Forms } from "../../admin-utils";
import swal from "sweetalert";
import { confirmDialog } from "../../utils/dialogs";
import { usePusher } from "../../PusherContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2)
  },
  message: {
    fontSize: 15,
    color: "#848484"
  },
  news: {
    backgroundColor: "#FCF9FC"
  },
  campaign: {
    backgroundColor: "#FCF9FC"
  },
  announcement: {
    backgroundColor: "#FFFDF9"
  },
  share_knowledge: {
    backgroundColor: "#FCF9FC"
  },
  new_hire: {
    backgroundColor: "#FAFCFA"
  },
  alert: {
    backgroundColor: "#D30053",
    color: "#ffffff!important"
  },
  postIcon: {
    marginRight: theme.spacing(2),
    "& img": {
      width: 50,
      height: 50
    }
  },
  withIcon: {
    padding: theme.spacing(2, 3, 2, 3),
    paddingBottom: theme.spacing(1),
    marginRight: -theme.spacing(3),
    marginLeft: -theme.spacing(3)
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    boxShadow: theme.shadows[20],
    width: 500,
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%",
    backgroundColor: "#fff",
    padding: theme.spacing(2)
  },
  subheader: {
    display: "flex",
    alignItems: "center"
  },
  accessTimeIcon: {
    color: theme.palette.text.secondary,
    fontSize: "14px",
    height: 14,
    width: 14,
    marginRight: 6
  },
  content: {
    paddingTop: 0
  },
  mediaArea: {
    marginBottom: theme.spacing(2)
  },
  media: {
    height: 400,
    backgroundPosition: "initial"
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  dot: {
    color: theme.palette.primary.main,
    top: 3,
    position: "relative",
    marginLeft: theme.spacing(1)
  },
  moreButton: {
    color: theme.palette.secondary.main
  },
  postAction: {
    marginTop: 8
  },
  unFollowButton: {
    backgroundColor: "#FDE6D1",
    color: "#E23D1F",
    "&:hover": {
      backgroundColor: "#FCC6A5"
    }
  },
  shareKnowledge: {
    border: "1px solid",
    borderColor: theme.palette.primary.main
  },
  shareComment: {
    padding: theme.spacing(2, 2, 0, 2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(3)
  },
  shareContainer: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      width: 2,
      height: "calc(100% - 3px)",
      backgroundColor: theme.palette.secondary.main,
      top: -41,
      left: 7,
      zIndex: -1
    }
  },
  shareCircle: {
    position: "absolute",
    left: 0,
    bottom: 30,
    color: theme.palette.secondary.main
  },
  groupArrow: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    top: 3,
    position: "relative"
  }
}));

function PostHeader({ post, onReply, onDelete, onEdit, ...rest }) {
  const classes = useStyles();

  let formRef;
  let formRef2;
  let formRef3;

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    user: { member_id },
    token
  } = useSelector(state => state.session);
  const { members: followings, posts: followedPosts } = useSelector(
    state => state.followings
  );
  const dispatch = useDispatch();
  const pusher = usePusher();

  const followingMember = followings.find(i => i === post.created_by);
  const isFollowing = followedPosts.find(i => i === post.id);

  const postType = post.post_type;
  const noReport = [
    "announcement",
    "new_hire",
    "campaign",
    "alert",
    "news"
  ].includes(postType);
  const isShareKnowledge = postType === "share_knowledge";

  const isMine = post.created_by == member_id;

  const openDropdownMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!post.is_mine) {
      const followChannel = pusher.subscribe(
        `private-followers-channel-${member_id}`
      );
      followChannel.bind("client-followers", function(data) {
        followMember(data.member, data.action);
      });
    }

    followMember(
      post.created_by,
      post.is_follow_member ? "follow" : "unfollow"
    );
    followPost(post.id, post.is_follow_post ? "follow" : "unfollow");
  }, []);

  const followMember = (member, action) => {
    if (action === "follow") {
      dispatch(FollowerAction.followMember(member));
    } else {
      dispatch(FollowerAction.unFollowMember(member));
    }
  };

  const followPost = (post, action) => {
    if (action === "follow") {
      dispatch(FollowerAction.followPost(post));
    } else {
      dispatch(FollowerAction.unFollowPost(post));
    }
  };

  // Toggle Follow member action
  const toggleFollowMemberAction = (action = "follow") => {
    const { created_by, creator } = post;

    let endpoint = "followers",
      apiCaller = postRequest,
      message = __(
        `You followed ${creator.name}`,
        `انت تتابع ${creator.name} الان`
      );

    if (action === "unfollow") {
      endpoint = "followers/unfollow";
      apiCaller = getData;
      message = __(
        `You unfollowed ${creator.name}`,
        `الغيت متابعة ${creator.name}`
      );
    }

    apiCaller(
      endpoint,
      {
        member_id: created_by,
        follower_id: member_id
      },
      token
    ).then(() => {
      followMember(created_by, action);

      // global.feedChannel.trigger('client-member-followers', {
      //   target: member_id,
      //   member: creator.id,
      //   action,
      // })
    });
  };

  // Toggle Follow post action
  const toggleFollowPostAction = (action = "follow") => {
    const { id: post_id } = post;

    let endpoint = "post_follower",
      apiCaller = postRequest,
      message = __(`You are following the post now`, `انت تتابع المشاركة الان`);

    if (action === "unfollow") {
      endpoint = "post_follower/unfollow";
      apiCaller = getData;
      message = __(`You unfollowed the post`, `الغيت متابعة المشاركة`);
    }

    apiCaller(
      endpoint,
      {
        member_id: member_id,
        post_id
      },
      token
    ).then(() => {
      followPost(post_id, action);

      // global.feedChannel.trigger('client-post-followers', {
      //   target: member_id,
      //   post: post_id,
      //   action,
      // })
    });
  };

  // Delete post action
  const handleDeletePost = () => {
    confirmDialog({
      title: lang(
        "Are you sure you want to delete this post?",
        "هل انت متأكد انك تريد حذف المشاركة؟"
      ),
      onConfirm: () => {
        onDelete && onDelete();
      },
      onConfirmMessage: lang("Post has been deleted", "تم حذف المشاركة")
    });
  };

  return (
    <>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            alt={post.creator.name}
            src={post.creator.image}
            to={`/profile/${post.creator.username}`}
          />
        }
        disableTypography
        subheader={
          <div className={classes.subheader}>
            <Typography variant="body2" color={"primary"}>
              {moment(post.created_at).fromNow()}
            </Typography>
          </div>
        }
        title={
          <>
            <Link
              color="textPrimary"
              component={RouterLink}
              to={`/profile/${post.creator.username}`}
              variant="h5"
            >
              {post.creator.name}
            </Link>

            {post.group && (
              <Link
                color="textPrimary"
                component={RouterLink}
                to={`/groups/${post.group_id}`}
                variant="h5"
              >
                <HiOutlineArrowNarrowLeft className={classes.groupArrow} />{" "}
                {post.group.name}
              </Link>
            )}

            {!isMine && <GoPrimitiveDot className={classes.dot} />}

            {!isMine && (
              <Button
                variant="text"
                size={"small"}
                color="primary"
                onClick={() => {
                  toggleFollowMemberAction(
                    followingMember ? "unfollow" : "follow"
                  );
                }}
              >
                {followingMember
                  ? __("إلغاء المتابعة", "UnFollow")
                  : __("متابعة", "Follow")}
              </Button>
            )}
          </>
        }
        action={
          <div className={classes.postAction}>
            {(!isMine || isShareKnowledge) && (
              <Button
                className={
                  isFollowing && !isShareKnowledge && classes.unFollowButton
                }
                color="primary"
                size="small"
                variant="contained"
                onClick={() => {
                  if (isShareKnowledge) {
                    formRef.show();
                  } else {
                    toggleFollowPostAction(isFollowing ? "unfollow" : "follow");
                  }
                }}
              >
                {isShareKnowledge
                  ? __("إجابة", "Answer")
                  : isFollowing
                  ? __("إلغاء المتابعة", "UnFollow")
                  : __("متابعة المشاركة", "Follow Post")}
              </Button>
            )}

            <IconButton
              onClick={openDropdownMenu}
              className={classes.moreButton}
              size="small"
            >
              <HiDotsVertical size={20} />
            </IconButton>
          </div>
        }
      />

      <Forms
        hide
        onRef={ref => (formRef3 = ref)}
        title={__("اضافة مشاركة جديد", "Add New Post")}
        editTitle={__("تعديل المشاركة", "Edit Post")}
        buttons={["cancel", "saveExit"]}
        saveExitTitle={lang("Save Post", "حفظ المشاركة")}
        endpoint={"post"}
        hidden={{
          post_type: post.post_type
        }}
        fields={[
          {
            title: __("العنوان", "Title"),
            field: "title",
            type: "string"
          },
          {
            title: __(
              "شارك، تحدث،ألهم مجتمعك",
              "Share,Talk,Inspire Your Community"
            ),
            field: "description",
            type: "editor",
            rules: ["required"]
          }
        ]}
        onFinish={data => {
          onEdit && onEdit(data);
        }}
      />

      <Forms
        hide
        onRef={ref => (formRef = ref)}
        title={__("اضافة إجابة", "Answer")}
        endpoint={"post_comment"}
        buttons={["cancel", "saveExit"]}
        saveExitTitle={lang("Send", "ارسال")}
        hidden={{
          post_id: post.id,
          created_by: member_id,
          main_thread: 0,
          include:
            "member,reactions,comments,comments.reactions,comments.member"
        }}
        fields={[
          {
            title: __("اكتب إجابة", "Write answer"),
            field: "message",
            type: "text",
            rules: ["required"]
          },
          {
            field: "gallery",
            type: "file",
            multiple: true,
            size: 2000
          }
        ]}
        onFinish={data => {
          onReply && onReply(data);
        }}
      />

      <Forms
        hide
        onRef={ref => (formRef2 = ref)}
        title={__("التبليغ عن مشاركة", "Add Post Report")}
        editTitle={__("تعديل التبليغ", "Edit Post Report")}
        endpoint={"post_report"}
        buttons={["cancel", "saveExit"]}
        saveExitTitle={lang("Report", "إبلاغ")}
        hidden={{
          post_id: post.id,
          member_id
        }}
        fields={[
          {
            title: __("سبب البلاغ", "Reason"),
            field: "reason",
            type: "text",
            rules: ["required"]
          }
        ]}
        onFinish={data => {
          if (!data) {
            swal({
              text: lang(
                "You already reported this post",
                "لقد قمت بالابلاغ عن المشاركة من قبل"
              ),
              icon: "error",
              timer: 2000,
              buttons: false
            }).finally();

            return;
          }

          swal({
            text: lang(`Post reported successfully`, `تم الابلاغ عن المشاركة`),
            icon: "success",
            timer: 2000,
            buttons: false
          }).finally();
        }}
      />

      <DropdownMenu
        handleClose={handleClose}
        anchorEl={anchorEl}
        items={
          post.is_mine
            ? [
                {
                  title: `${__("منشور", "Post")} #${post.id}`
                },
                {
                  title: __("تعديل المشاركة", "Edit Post"),
                  icon: VscEdit,
                  onClick: () => {
                    formRef3.show(post.id);
                  }
                },
                {
                  title: __("حذف المشاركة", "Delete Post"),
                  icon: RiDeleteBin6Line,
                  onClick: () => {
                    handleDeletePost();
                  }
                }
              ]
            : noReport
            ? [
                {
                  title: `${__("منشور", "Post")} #${post.id}`
                }
              ]
            : [
                {
                  title: `${__("منشور", "Post")} #${post.id}`
                },
                {
                  title: __("تبليغ عن المشاركة", "Report Post"),
                  onClick: () => {
                    formRef2.show();
                  }
                }
              ]
        }
      />
    </>
  );
}

PostHeader.propTypes = {
  post: PropTypes.object.isRequired
};

export default PostHeader;
