export function humanFileSize(bytes, si = true) {
  let u, b = bytes, t = si ? 1000 : 1024;
  ['', si ? 'k' : 'K', ...'MGTPEZY'].find(x => (u = x, b /= t, b ** 2 < 1))
  return `${u ? (t * b).toFixed(1) : bytes} ${u}B`
}

export function getExtension(name) {
  let re = /(?:\.([^.]+))?$/
  return re.exec(name)[1]
}

export function getFileName(file) {
  return file.match(/^.*?([^\\/.]*)[^\\/]*$/)[1].replace(/_/g, '-')
}
