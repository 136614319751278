import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography
} from "@material-ui/core";
import {
  HiOutlineDownload,
  MdClose,
  RiShareForwardLine
} from "react-icons/all";
import Box from "@material-ui/core/Box";
import getIcon from "../../../utils/getIcon";
import { lang } from "../../../admin-utils/LanguageHelper";
import IconButton from "@material-ui/core/IconButton";
import { Page } from "../../../admin-utils";
import ImagePreview from "./ImagePreview";
import { saveAs } from "file-saver";
import VideoPreview from "./VideoPreview";
import AudioPreview from "./AudioPreview";

const useStyles = makeStyles(theme => ({
  icon: {
    width: 20
  },
  content: {
    height: "calc(100vh - 200px)",
    padding: 0
  }
}));

function FilePreview({ file, shareFile }) {
  let formRef;
  const classes = useStyles();

  const mediaFile = file.file;
  const media = mediaFile.media && mediaFile.media[mediaFile.media.length - 1];
  const filename = `${file.name || media.filename}.${media.extension}`;
  const fileLink = mediaFile.url[mediaFile.url.length - 1];

  const isImage = ["image", "vector"].includes(media.aggregate_type);
  const isVideo = ["mp4"].includes(media.extension);
  const isAudio = ["mp3"].includes(media.extension);

  const downloadFile = () => {
    saveAs(fileLink, filename);
  };

  // Images preview
  if (isImage) return <ImagePreview filename={filename} link={fileLink} />;

  // Video preview
  if (isVideo) return <VideoPreview link={fileLink} />;

  // Audio preview
  if (isAudio) return <AudioPreview link={fileLink} filename={filename} />;

  // Other documents
  return (
    <Box className={classes.root}>
      <CardHeader
        title={<Typography variant="h6">{filename}</Typography>}
        avatar={
          <img src={getIcon(media)} alt="file icon" className={classes.icon} />
        }
        action={
          <Box>
            <Tooltip
              title={lang("Share File", "مشاركة الملف")}
              placement={"right"}
            >
              <IconButton onClick={() => shareFile && shareFile()}>
                <RiShareForwardLine />
              </IconButton>
            </Tooltip>

            <Tooltip
              title={lang("Download File", "تحميل الملف")}
              placement={"right"}
            >
              <IconButton onClick={downloadFile}>
                <HiOutlineDownload />
              </IconButton>
            </Tooltip>

            <IconButton onClick={() => Page.hideView()}>
              <MdClose />
            </IconButton>
          </Box>
        }
      />

      <Divider />

      <CardContent className={classes.content}>
        <iframe
          src={`https://drive.google.com/viewerng/viewer?url=${fileLink}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
          frameBorder={0}
          width="100%"
          height="100%"
        />
      </CardContent>
    </Box>
  );
}

export default FilePreview;
