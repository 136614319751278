/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink, Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Avatar,
  Button,
  colors,
  Hidden,
  IconButton,
  TextField,
  MenuItem,
  Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import __ from "../../admin-utils/LanguageHelper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Clock from "react-live-clock";
import moment from "moment";
// import 'moment/locale/ar'
import Badge from "@material-ui/core/Badge";
import {
  AiFillCaretDown,
  AiOutlineGlobal,
  AiOutlineLock,
  BsChatDots,
  FiLogOut,
  HiOutlineUserGroup,
  VscPreview
} from "react-icons/all";
import withStyles from "@material-ui/core/styles/withStyles";
import "./moment-locale";
import DropdownMenu from "../../components/DropdownMenu";
import "lightbox-react/style.css";
import "video-react/dist/video-react.css";
import Modal from "@material-ui/core/Modal";
import ChangePassword from "../../views/ChangePassword";
import Notifications from "./Notifications";
import DirectionToggle from "../../components/DirectionToggle";
import FriendsDrawer from "../../components/FriendsDrawer";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { Autocomplete } from "@material-ui/lab";
import { getData } from "../../admin-utils/API";
import { useHistory } from "react-router";
import AuthActions from "../../redux/reducers/AuthRedux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#ffffff",
    height: 120,
    zIndex: 9999
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: "#ECF3F6",
    borderRadius: 4,
    flexBasis: 400,
    height: 40,
    padding: theme.spacing(0, 2),
    display: "flex",
    alignItems: "center"
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: "#8A98BA"
  },
  searchInput: {
    flexGrow: 1,
    color: "#303030",
    fontSize: 14,
    "& input::placeholder": {
      opacity: 1,
      color: "#A9B5CE",
      position: "relative"
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 450
  },
  autocompleteInput: {
    width: "100%"
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    "&:hover": {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    color: theme.palette.primary.main
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  name: {
    color: theme.palette.primary.main
  },
  logo: {
    height: 120,
    width: 120,
    padding: theme.spacing(2)
  },
  logoContainer: {
    background: "#fff"
  },
  headerContainer: {
    textAlign: "left",
    width: 256
  },
  topContainer: {
    height: 75,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  tabsContainer: {},
  tabButton: {
    minWidth: 100
  },
  timeContainer: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 300
  },
  time: {
    color: theme.palette.text.secondary,
    fontSize: 36,
    fontWeight: 400
  },
  meridiem: {
    marginLeft: 5
  },
  accountContainer: {
    width: 280,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  notificationsContainer: {
    width: 200,
    display: "flex",
    justifyContent: "center"
  },
  mobileNavContainer: {
    position: "sticky",
    top: 0,
    zIndex: 999,
    padding: "0 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  headerButton: {
    backgroundColor: "#CFD5EC",
    color: "#fff",
    marginRight: 20
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: 5
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxHeight: "100%",
    overflowY: "auto",
    maxWidth: "100%"
  }
}));

const BootstrapInput = withStyles(theme => ({
  input: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderRight: "1px solid #c0c6c8",
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ECF3F6",
    fontSize: 15,
    padding: "10px 13px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)" //
    }
  }
}))(InputBase);

const savedDirection = localStorage.getItem("direction");

function TopBar({ onOpenNavBarMobile, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [selectedSearchSection, setSelectedSearchSection] = useState("people");
  const [activeTab, setActiveTab] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showFriends, setShowFriends] = useState(false);

  const {
    token,
    user: { name, image, member_id }
  } = useSelector(state => state.session);
  let location = useLocation();
  const { chatNotifications: notificationsCount } = useSelector(
    state => state.pusher
  );

  const [direction, setDirection] = useState(savedDirection);
  const OtherLanguage = direction === "rtl" ? "en" : "ar";

  moment.locale(__("awasul", "en"));

  const handleDirectionToggle = () => {
    setDirection(prevDirection => {
      const direction = prevDirection === "rtl" ? "ltr" : "rtl";
      localStorage.setItem("direction", direction);
      return direction;
    });

    setTimeout(() => {
      window.location.reload();
    });
  };

  const StyledBadge = withStyles(theme => ({
    badge: {
      backgroundColor: props => props.background,
      fontFamily: "Roboto",
      top: -5,
      right: -5
    }
  }))(Badge);

  const handleSearchChange = event => {
    setSearchValue(event.target.value);
  };

  const handleSelectChange = event => {
    setSelectedSearchSection(event.target.value);
  };

  useEffect(() => {
    // Listen to location change to deactivate the top tabs
    let tabsPaths = [
      "/feed/campaign",
      "/feed/announcement",
      "/feed/share_knowledge",
      "/projects"
    ];
    if (!tabsPaths.includes(location.pathname)) {
      setActiveTab(null);
    }
  }, [location]);

  useEffect(() => {
    if (searchValue) {
      switch (selectedSearchSection) {
        case "people":
          getFriends(searchValue).finally();
          break;
        case "tasks":
          getTasks(searchValue).finally();
          break;
        case "projects":
          getProjects(searchValue).finally();
          break;
        default:
          setSearchData([]);
      }
    }
  }, [selectedSearchSection, searchValue]);

  async function getFriends(searchValue) {
    let fetchedData = await getData(
      `member`,
      {
        search: searchValue,
        excludeMe: true
      },
      token
    );

    setSearchData(fetchedData.data);
  }

  async function getTasks(searchValue) {
    let fetchedData = await getData(
      `task`,
      {
        filtering: [{ key: "name", filter: "like", value: searchValue }],
        excludeMe: true
      },
      token
    );

    setSearchData(fetchedData.data);
  }

  async function getProjects(searchValue) {
    let fetchedData = await getData(
      "project",
      {
        search: searchValue,
        excludeMe: true
      },
      token
    );

    setSearchData(fetchedData.data);
  }

  const handleSearchSubmit = () => {
    switch (selectedSearchSection) {
      case "people":
        history.push(`/profile/${searchData[0].username}`);
        break;
      case "tasks":
        console.log(searchData);
        history.push(`/tasks/${searchData[0].id}`);
        break;
      case "projects":
        history.push(`/projects/${searchData[0].id}`);
        break;
      default:
        return;
    }
  };

  const handleProfileMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const greeting = () => {
    const hour = moment().hour();

    if (hour > 16) {
      return __("مساء الخير", "Good evening");
    }

    if (hour > 11) {
      return __("طاب مسائك", "Good afternoon");
    }

    return __("صباح الخير", "Good morning");
  };

  const dropDownMenu = (
    <DropdownMenu
      anchorEl={anchorEl}
      handleClose={handleClose}
      items={[
        {
          title: __("حسابي", "My Account"),
          icon: VscPreview,
          link: "/profile"
        },
        {
          title: __("الأصدقاء", "Friends"),
          icon: HiOutlineUserGroup,
          onClick: () => setShowFriends(true)
        },
        {
          title: __("تغيير كلمة المرور", "Change Password"),
          icon: AiOutlineLock,
          onClick: () => {
            setShowChangePassword(true);
          }
        },
        {
          title: __("English", "اللغة العربية"),
          icon: AiOutlineGlobal,
          onClick: () => {
            handleDirectionToggle();
          }
        },
        {
          title: __("تسجيل خروج", "Logout"),
          icon: FiLogOut,
          link: "/logout"
        }
      ]}
    />
  );

  return (
    <>
      {/*Mobile Toolbar*/}
      <Hidden lgUp>
        <div className={classes.mobileNavContainer}>
          <div>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onOpenNavBarMobile}
            >
              <MenuIcon />
            </IconButton>
            <Button
              onClick={handleProfileMenu}
              size={"small"}
              color="secondary"
              endIcon={
                <AiFillCaretDown style={{ top: -2, position: "relative" }} />
              }
            >
              <Avatar alt="Person" className={classes.avatar} src={image} />
            </Button>
            {dropDownMenu}
          </div>
          <div>
            <Notifications />
            <StyledBadge
              component={RouterLink}
              to={"/chat"}
              background={"#AA089B"}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              badgeContent={notificationsCount}
            >
              <BsChatDots />
            </StyledBadge>
          </div>
        </div>
      </Hidden>

      {/*Desktop Toolbar*/}
      <Hidden mdDown>
        <AppBar
          {...rest}
          className={clsx(classes.root, className)}
          color="secondary"
          elevation={1}
        >
          <Toolbar>
            <div className={classes.headerContainer}>
              <img
                src={`/images/${__(
                  "logo_full_arabic.png",
                  "logo_full_english.png"
                )}`}
                className={classes.logo}
                alt="Awasul logo"
              />
            </div>

            <div style={{ flex: 1 }}>
              <div className={classes.topContainer}>
                <div className={classes.timeContainer}>
                  <span className={classes.time}>
                    <Clock format={"hh:mm"} ticking={true} />
                  </span>

                  <span className={classes.meridiem}>
                    <Clock format={"A"} ticking={true} />
                  </span>

                  <div>
                    <Clock format={"DD MMMM YYYY"} ticking={true} />
                  </div>
                </div>

                <div className={classes.searchContainer}>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={selectedSearchSection}
                    onChange={handleSelectChange}
                    input={<BootstrapInput />}
                  >
                    <MenuItem value={"people"}>
                      {__("بحث بالأشخاص", "Search People")}
                    </MenuItem>
                    <MenuItem value={"tasks"}>
                      {__("بحث بالمهام", "Search Tasks")}
                    </MenuItem>
                    <MenuItem value={"projects"}>
                      {__("بحث بالمشاريع", "Search Projects")}
                    </MenuItem>
                  </Select>
                  <div className={classes.search} ref={searchRef}>
                    <SearchIcon className={classes.searchIcon} />
                    <Autocomplete
                      disableClearable
                      options={searchData}
                      getOptionLabel={option => option.name}
                      renderOption={option =>
                        selectedSearchSection === "people" ? (
                          <>
                            <Avatar
                              style={{
                                width: 25,
                                height: 25,
                                marginLeft: 10
                              }}
                              alt={option.name + "Avatar"}
                              src={option.image}
                            />
                            {option.name}
                          </>
                        ) : (
                          option.name
                        )
                      }
                      className={classes.autocompleteInput}
                      freeSolo
                      renderInput={params => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true
                          }}
                          variant="standard"
                          className={classes.searchInput}
                          onChange={handleSearchChange}
                          placeholder={__(
                            "اكتب هنا للبحث",
                            "Write here to search"
                          )}
                          value={searchValue}
                          onKeyPress={ev => {
                            if (ev.key === "Enter" && searchData.length >= 1) {
                              handleSearchSubmit();
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className={classes.notificationsContainer}>
                  <Notifications />

                  <IconButton
                    className={classes.headerButton}
                    aria-label="chat"
                    component={RouterLink}
                    to={"/chat"}
                  >
                    <StyledBadge
                      background={"#AA089B"}
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      badgeContent={notificationsCount}
                    >
                      <BsChatDots />
                    </StyledBadge>
                  </IconButton>
                </div>

                <div className={classes.accountContainer}>
                  <Button
                    onClick={handleProfileMenu}
                    size={"small"}
                    color="secondary"
                    endIcon={
                      <AiFillCaretDown
                        style={{ top: -2, position: "relative" }}
                      />
                    }
                  >
                    <Avatar
                      alt="Person"
                      className={classes.avatar}
                      src={image}
                    />
                    {greeting()} {name}
                  </Button>

                  <DirectionToggle
                    direction={OtherLanguage}
                    onToggle={handleDirectionToggle}
                  />

                  {dropDownMenu}
                </div>
              </div>
              <div className={classes.tabsContainer}>
                <Tabs
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="quick navigation"
                  value={activeTab}
                  onChange={(event, newTab) => setActiveTab(newTab)}
                >
                  {[
                    {
                      title: __("التعميمات", "Announcements"),
                      link: "/feed/announcement"
                    },
                    {
                      title: __("الحملات", "Campaigns"),
                      link: "/feed/campaign"
                    },
                    {
                      title: __("تبادل الخبرة", "Share Knowledge"),
                      link: "/feed/share_knowledge"
                    },
                    {
                      title: __("ادارة المشاريع", "Projects"),
                      link: "/projects"
                    }
                  ].map(({ title, link }) => (
                    <Tab
                      label={title}
                      className={classes.tabButton}
                      component={Link}
                      to={link}
                    />
                  ))}
                </Tabs>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>

      <Modal
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      >
        <div className={classes.modal}>
          <ChangePassword onDone={() => setShowChangePassword(false)} />
        </div>
      </Modal>

      <FriendsDrawer
        isOpen={showFriends}
        onClose={() => setShowFriends(false)}
      />
    </>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
