import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {makeStyles} from "@material-ui/styles";
import Link from "@material-ui/core/Link";
import __, {lang} from "../../admin-utils/LanguageHelper";
import isImage from "is-image";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Paper, Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import getIcon from "../../utils/getIcon";
import Lightbox from "lightbox-react";
import _ from "lodash";
import {Player} from "video-react";
import {FaPlayCircle} from "react-icons/all";
import {getExtension, getFileName, humanFileSize} from "../../utils/files";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2)
  },
  overlay: {
    top: 4,
    right: 4,
    color: "#ffffff",
    left: 4,
    bottom: theme.spacing(1),
    position: "absolute",
    borderRadius: 8,
    fontSize: "30px",
    backgroundColor: "rgb(37 37 37 / 83%)",
    zIndex: 1,
    cursor: "pointer",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    direction: __("rtl", "ltr"),
    fontFamily: "monospace"
  },
  icon: {
    width: 20
  },
  paper: {
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    "&:hover": {
      boxShadow: theme.shadows[4],
      textDecoration: "none"
    }
  },
  filesContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  fileSize: {
    position: "relative",
    top: -theme.spacing(0.5)
  },
  fileName: {
    width: "calc(100% - 15px)",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  videoContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .video-react": {
      maxWidth: 800
    }
  },
  playerIcon: {
    position: "absolute",
    top: "calc(50% - 15px)",
    right: "calc(50% - 15px)",
    color: "#fff",
    zIndex: 1,
    fontSize: 35
  }
}));

function isVideo(file) {
  return ["mp4"].includes(getExtension(file));
}

const VideoPlayer = ({ link }) => {
  const classes = useStyles();

  return (
    <Box className={classes.videoContainer}>
      <Player>
        <source src={link} />
      </Player>
    </Box>
  );
};

function FilesGallery({ images, small, ...rest }) {
  const classes = useStyles();

  const [photoIndex, setPhotoIndex] = useState(0);
  const [totalCols, setTotalCols] = useState(3);
  const [totalImages, setTotalImages] = useState(0);
  const [cellHeight, setCellHeight] = useState(160);
  const [imagesList, setImagesList] = useState([]);
  const [imageVideoFiles, setImageVideoFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function extractImagesVideos() {
    let imageVideos = [],
      otherFiles = [];

    if (!_.isArray(images.url)) return;

    images.url.map((i, index) => {
      if (isImage(i) || isVideo(i)) {
        imageVideos.push({
          url: i,
          media: images.media[index]
        });
      } else {
        otherFiles.push({
          url: i,
          media: images.media[index]
        });
      }
    });

    setOtherFiles(otherFiles);
    setImageVideoFiles(imageVideos);
    setTotalImages(imageVideos.length);
  }

  useEffect(() => {
    extractImagesVideos();
  }, [images]);

  useEffect(() => {
    if (totalImages === 1) {
      setCellHeight(small ? 120 : 220);
    }

    if (totalImages === 2 || totalImages === 3) setTotalCols(2);

    imageVideoFiles.map((image, index) => {
      let cols = 1;

      if (totalImages === 1) {
        cols = 3;
      }

      if (totalImages === 3) {
        if (index < 2) cols = 1;
        if (index === 2) cols = 2;
      }

      if (totalImages === 4) {
        if (index === 0 || index === 3) {
          cols = 2;
        } else {
          cols = 1;
        }
      }

      if (totalImages >= 5) {
        if (index === 0) cols = 2;
      }

      if (index < 5) {
        imagesList.push({
          key: index.toString(),
          title: image.url,
          link: image.url,
          cols
        });
      }
    });

    setImagesList(imagesList);
  }, [totalImages]);

  const openLightBox = index => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  function processLightboxContent(link) {
    if (isVideo(link)) return <VideoPlayer link={link} />;

    return link;
  }

  return (
    <div {...rest} className={clsx(classes.root)}>
      <Grid container spacing={1}>
        {imageVideoFiles.map((image, index) => {
          // console.log(image)
          return (
            <Grid
              item
              xs={4}
              style={{
                position: "relative",
                borderRadius: 8,
                overflow: "hidden"
              }}
            >
              {imageVideoFiles.length > 6 && index === 5 && (
                <div
                  className={classes.overlay}
                  onClick={() => openLightBox(index)}
                >
                  +{totalImages - 5}
                </div>
              )}

              {isVideo(image.url) && (
                <FaPlayCircle className={classes.playerIcon} />
              )}

              {index < 6 && (
                <Link component="button" onClick={() => openLightBox(index)}>
                  {isVideo(image.url) ? (
                    <video src={image.url} />
                  ) : (
                    <img
                      src={image.url}
                      alt={image.title}
                      style={{ height: 150, width: "100%", borderRadius: 8, objectFit: 'cover', objectPosition: 'center top' }}
                    />
                  )}
                </Link>
              )}
            </Grid>
          );
        })}
      </Grid>

      {/*<GridList cellHeight={cellHeight} className={classes.gridList} style={(small && imagesList.length === 1) && {width: 120}} cols={totalCols}>*/}
      {/*  {imagesList.map((image, index) => (*/}
      {/*    <GridListTile key={image.key} cols={image.cols}>*/}
      {/*      {(totalImages > 5 && Number(image.key) === 4) && (*/}
      {/*        <div className={classes.overlay} onClick={() => openLightBox(index)}>+{totalImages - 4}</div>*/}
      {/*      )}*/}

      {/*      {isVideo(image.link) && <FaPlayCircle className={classes.playerIcon}/>}*/}

      {/*      <Link*/}
      {/*        component="button"*/}
      {/*        onClick={() => openLightBox(index)}*/}
      {/*      >*/}
      {/*        {isVideo(image.link) ? (*/}
      {/*          <video src={image.link}/>*/}
      {/*        ) : (*/}
      {/*          <img src={image.link} alt={image.title} style={{minHeight: cellHeight, maxWidth: '100%', borderRadius: 8}}/>*/}
      {/*        )}*/}
      {/*      </Link>*/}
      {/*    </GridListTile>*/}
      {/*  ))}*/}
      {/*</GridList>*/}

      {otherFiles.length > 0 && (
        <Box className={classes.filesContainer}>
          <Typography variant={"h6"} gutterBottom>
            {lang("ATTACHMENTS", "المرفقات")}
          </Typography>

          <Grid container spacing={1}>
            {otherFiles.map(({ url: file, media }) => (
              <Grid item xs={4}>
                <Tooltip
                  title={lang("Download File", "تحميل الملف")}
                  placement={"top"}
                >
                  <Paper
                    className={classes.paper}
                    component={Link}
                    href={file}
                    target={"_blank"}
                  >
                    <Box p={1}>
                      <img
                        src={getIcon(media)}
                        alt="file icon"
                        className={classes.icon}
                      />
                    </Box>

                    <Box flex={1}>
                      <Typography
                        variant={"body2"}
                        color={"textPrimary"}
                        className={classes.fileName}
                      >
                        {getFileName(file)}
                      </Typography>
                      <Typography
                        variant={"caption"}
                        className={classes.fileSize}
                      >
                        {humanFileSize(media.size)}
                      </Typography>
                    </Box>
                  </Paper>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {isOpen && (
        <Lightbox
          mainSrc={processLightboxContent(
            imageVideoFiles.map(i => i.url)[photoIndex]
          )}
          nextSrc={
            imageVideoFiles.map(i => i.url)[
              (photoIndex + 1) % imageVideoFiles.length
            ]
          }
          prevSrc={
            imageVideoFiles.map(i => i.url)[
              (photoIndex + imageVideoFiles.length - 1) % imageVideoFiles.length
            ]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageVideoFiles.length - 1) % imageVideoFiles.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageVideoFiles.length)
          }
        />
      )}
    </div>
  );
}

FilesGallery.propTypes = {
  images: PropTypes.array.isRequired
};

export default FilesGallery;
